<template>
  <div class="page main-signin-wrapper construction">
    <div class="container">
    <div class="row">
      <div class="col text-center">
        <div class="error-code">404</div>
        <p class="lead error-message">
          OOPS! NOTHING WAS FOUND
          <br>
          The page you are looking for might have been removed had its name changed or is temporarily unavailable. 

        </p>
        <button @click="$router.push({name: 'home'})" class="px-2 home-link mt-2"> back to home </button>
 
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style>
  .page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    background: #f8f9fa;
    color: #6c757d;
    font-family: 'Arial', sans-serif;
  }
  .error-code {
    font-size: 6em;
    color: var(--primary-color);
  }
  .error-message {
    text-align: center;
  }

  .home-link {
    color: var(--primary-color);
    text-decoration: none;
  }

</style>
