<template>
    <v-container>
      <Partners />
    </v-container>
  </template>
  
  <script>
  import Partners from "../components/Partners/Partners.vue";
  
  export default {
    components: {
        Partners,
    },
  };
  </script>
  