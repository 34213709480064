<template>
  <div class="icons d-flex align-center">
    <v-img src="../../assets/icon/transfer.png" width="38" class="px-2 iconbtn" @click="gotomap"></v-img>
    <!-- <v-img src="../../assets/icon/pull.png" width="34" class="px-2 iconbtn"></v-img> -->
    <!-- <v-img src="../../assets/icon/favoriteprofile.png" width="38" class="px-2 iconbtn"></v-img> -->
    <!-- <v-img src="../../assets/icon/plus.png" width="38" class="px-2 iconbtn" @click="addAd"></v-img> -->
  <!-- <button class="px-2 searchmap" @click="addAd">{{ $t("Real_request") }}</button>
  <button class="px-2 searchmap" @click="addAd">{{ $t("Real_offer") }}</button> -->

  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    gotomap(){
        this.$router.push({name:'Map'})
    },
   
  },
};
</script>
<style lang="scss" scoped>
.iconbtn{
  cursor: pointer;
}
.searchmap{
   background-color: #F6C712;
   border-radius: 6px;
   font-weight: 500;
   height: 40px;
   width: 90px;
   margin: 0 5px;
}
</style>
