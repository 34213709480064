<template>
  <v-row justify="center">
    <v-col cols="12" xs="12" sm="7" md="5" class="pa-xs-3 pa-0 py-5">
      <v-card class="pa-xs-5 py-md-16 my-md-10 my-0  py-5 px-2 card_login " elevation="0">
        <v-card-title class="text-h4 weight_text"
          >{{ $t("ForgetPassword") }}
        </v-card-title>

        <v-card-text>
          <v-form @submit.prevent="handlevrefication">
            <label class="weight_text">
              {{ $t("phone_number") }}
            </label>
            <v-text-field
            type="tel" 
              density="compact"
              variant="solo"
              :placeholder="$t('phone_number')"
              single-line
              hide-details
              class="border rounded mt-2 mb-6 tel"
              elevation="0"
              v-model="loginForm.phone"
              @input="validatePhoneNumber" 
              
            >
            </v-text-field>
            <div v-if="invalidPhoneNumber" class="text-red mb-2">
      {{ validationMessage }}
    </div>
            <div class="text-red mb-2">{{ errorMessage ? errorMessage : "" }}</div>

            <button type="submit" class="previewads d-block w-100">
              {{ $t("Send") }}
            </button>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="dialog" width="700">
      <v-card class="text-center py-5 " style="direction: ltr !important;">
        <h3
          class="w-max-content mx-auto rounded pa-1 text-h5 font-weight-bold"
        >
          OTP
        </h3>
        <v-otp-input
          focused
          v-model="verify"
          focus-all
          variant="solo"
          length="4"
        ></v-otp-input>
        <button class="previewads d-block py-2 px-4" @click="verifyication">
              {{ $t("Verify") }}
            </button>
        <div class="text-red">{{ errorverify ? errorverify : "" }}</div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import crudDataService from "@/Services/crudDataService";

export default {
  data() {
    localStorage.removeItem('userId');
    return {
      dialog: false,
      loginForm: {
        phone: "",
      },
      verify:"",
      errorverify:"",
      errorMessage: "",
      invalidPhoneNumber:false,
      validationMessage:""
    };
  },
  methods: {

    validatePhoneNumber() {

      // Simple check to ensure the phone number does not start with a negative sign
      // and contains only digits. You can replace this with a more robust validation if needed.
      const isNegative = this.loginForm.phone.startsWith('-');
      const isNumeric = /^\d+$/.test(this.loginForm.phone);

      this.invalidPhoneNumber = isNegative || !isNumeric;

      // If you have a localization library like vue-i18n, you can use it to switch messages
      this.validationMessage = this.$t('enterValidPhoneNumber');
    },
    async handlevrefication() {
      await crudDataService
        .create(`forget-password`, this.loginForm)
        .then((res) => {
          this.dialog = true;
          // // console.log(res.data.data);
        })
        .catch((error) => {
          // console.log(error.request);
          // console.log(error.response);
          // console.log(error.response.data.message);
          if (error.response) {
            // The API responded with an error message
            this.errorMessage = error.response.data.message;
          } else if (error.request) {
            this.errorMessage = "No response from the server";
          } else {
            this.errorMessage = error.message;
          }
          console.error("password error:", this.errorMessage);
        });
    },
    async verifyication() {
       await crudDataService
        .create(`verify`, {code:this.verify,
        phone:this.loginForm.phone})
        .then((res) => {
          this.dialog = false;

          localStorage.setItem('authToken_ResetPassword', res.data.data.token);
          localStorage.setItem('userId_ResetPassword', res.data.data.id);
          // console.log(res.data.data);
          // console.log( localStorage.getItem('authToken_ResetPassword'));
          this.$router.push({ name: 'ResetPassword' });
        })
        .catch((error) => {
          // console.log(error.request);
          // console.log(error.response);
          // console.log(error.response.data.message);
          if (error.response) {
            // The API responded with an error message
            this.errorverify = error.response.data.message;
          } else if (error.request) {
            this.errorverify = "No response from the server";
          } else {
            this.errorverify = error.message;
          }
          console.error("password error:", this.errorverify);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.card_login {
  .weight_text {
    font-weight: 900;
    text-align: center;
    font-size: 16px;
  }
  .previewads {
    height: 45px;
    font-weight: 900;
  }
}

</style>
<style>
.tel input {
    text-align: end !important;
}
</style>