<template>
  <v-container>
    <section v-if="update">
      <div class="breadcrumbs my-5">
        <div
          class="font-weight-bold"
          style="font-size: 30px; cursor: pointer"
          @click="$router.go(-1)"
        >
          {{ $t("Profile") }}
        </div>
        <v-icon icon="mdi-slash-forward" style="font-size: 40px"></v-icon>
        <p style="font-size: 24px">
          {{ $t("Account_settings") }}
        </p>
      </div>
      <v-card class="pa-xs-5 px-2 card_update" elevation="0">
        <v-text-field
          density="compact"
          variant="solo"
          single-line
          hide-details
          class="rounded mt-2 mb-3 border-style font-weight-medium"
          elevation="0"
          v-model="update.name"
        >
        </v-text-field>
        <v-text-field
          density="compact"
          variant="solo"
          single-line
          hide-details
          class="rounded mt-2 mb-3 border-style font-weight-medium"
          v-model="update.email"
        >
        </v-text-field>
        <v-text-field
          density="compact"
          variant="solo"
          single-line
          hide-details
          class="rounded mt-2 mb-3 border-style font-weight-medium"
          v-model="update.phone"
        >
        </v-text-field>
        <v-text-field
          density="compact"
          variant="solo"
          single-line
          hide-details
          class="rounded mt-2 mb-3 border-style font-weight-medium"
          elevation="0"
          v-model="update.whatsapp"
        >
        </v-text-field>
        <multiselect
      :options="optionscity"
      :searchable="true"
      :selected="null"
      :placeholder="$t('City')"
      label="name"
      style="border: 2px solid #f6c712;"
      v-model="update.city_id"
    ></multiselect>
    <v-file-input
    hide-details
    style="border: 2px solid #f6c712;"
      variant="solo"
      class="my-2 px-2 rounded"
      prepend-icon="mdi-camera"
      accept=".pdf, image/jpeg, image/png"
      @change="handleFileChange"
    ></v-file-input>
    <img :src="imageUrl" alt="">
        <div
          class="rounded mt-2 mb-3 pa-3 font-weight-medium"
          style="border: 2px solid #000; cursor: pointer"
          @click="changepassword"
        >
          {{ $t("Change_Password") }}
        </div>
        <div class="text-red">{{ errorMessage ? errorMessage : "" }}</div>
        <div class="mt-md-16 mt-3">
          <button
            class="previewads rounded d-block py-2 mb-3"
            style="width: 65%"
            @click="updateprofile"
          >
            {{ $t("Save") }}
          </button>
          <button
            class="ma-auto rounded d-block py-2 font-weight-bold text-white bg-red"
            style="width: 65%"
          >
            {{ $t("Delete") }}
          </button>
        </div>
      </v-card>
    </section>
    <v-row v-else>
      <v-col cols="12" v-for="text in texts" :key="text">
        <v-skeleton-loader
          class="mx-auto border-0"
          type="paragraph"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import crudDataService from "@/Services/crudDataService";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      optionscity: [],
      user: "",
      update: {
        name: "",
        email: "",
        phone: "",
        whatsapp:'',
        image:'',
        city_id:''
      },
      imageUrl:null,
      errorMessage: "",
      texts: [1, 2, 3, 4],
    };
  },
  methods: {
    handleFileChange(event) {
      this.update.image = event.target.files.item(0);
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
      };
      reader.readAsDataURL(this.update.image);
    },
    changepassword(){
      this.$router.push({name:"ChangePassword"});
    },
    async userprofile() {
      const id = localStorage.getItem("userId");
      let response = await crudDataService.get(`user-profile`, id);
      this.user = response.data.data;
      this.update.name = this.user.name;
      this.update.email = this.user.email;
      this.update.phone = this.user.phone;
      this.update.whatsapp = this.user.whatsapp;
      this.update.city_id = this.user.city?this.user.city.id:'';
      this.imageUrl = this.user.image;
    },
    async updateprofile() {
      await crudDataService
        .create(`user/update`, this.update, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        })
        .then((res) => {
          this.$router.push('/')
          // console.log(res.data.data, "updated");
        })
        .catch((error) => {
          if (error.response) {
            // The API responded with an error message
            this.errorMessage = error.response.data.message;
          } else if (error.request) {
            this.errorMessage = "No response from the server";
          } else {
            this.errorMessage = error.message;
          }
        });
    },
    async City() {
      let response = await crudDataService.getAll(`cities`);
      this.optionscity = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },
  },
  mounted() {
    this.userprofile();
    this.City();
  },
};
</script>
<style lang="scss">
.card_update{ .v-field--variant-solo,
.v-field--variant-solo-filled {
  box-shadow: none;
}}

.border-style {
  border: 2px solid #f6c712;
}
</style>
