<template>
    <v-container>
      <Terms/>
    </v-container>
  </template>
  
  <script>
  import Terms from '@/components/Terms/Terms.vue'
  export default {
  
    components: {
        Terms
    },
  };
  </script>
  