import { createApp } from 'vue'
import './axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import i18n from './i18n'
import './assets/style.scss'
import "@vueform/multiselect/themes/default.css";
import Swal from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueLeaflet from '@vdcrea/vue-leaflet'
import '@vdcrea/vue-leaflet/dist/vue-leaflet.css'
loadFonts()

// router.beforeEach((to, from, next) => {
//   console.log(localStorage.getItem('lang'));
  const language = localStorage.getItem('lang') || 'ar'; // Default to 'en' if not set
  document.documentElement.setAttribute('dir', language === 'ar' ? 'rtl' : 'ltr');

  // document.documentElement.setAttribute('dir', 'rtl');
  // next();
// });
createApp(App)
  .use(router)
  .use(i18n)
  .use(vuetify)
  .use(Swal)
  .use(VueLeaflet)
  .mount('#app')
