<template>
  <section v-if="items.length > 0" class="prten pt-md-10 pt-4">
    <v-container>
      <div class="text-center my-8">
      <p class="text-h4 font-weight-bold">
        {{ $t("Success_Partners") }}
      </p>
      <p style="color: #00000087; font-size: 28px" class="font-weight-bold">
        {{ $t("Partners_whose_partnership") }}
      </p>
    </div>
    <Carousel v-bind="settings" :autoplay="2200" :breakpoints="breakpoints" :wrap-around="true">
    <Slide v-for="(item, i) in items" :key="i">
      <div class="carousel__item partenr_img">
          <img :src="item.image" class="" />
      </div>
    </Slide>
  
    <template #addons>
      <Navigation />
    </template>
  </Carousel>
    </v-container>
 
   
  </section>
  <v-row v-else>
    <v-col cols="12" v-for="text in texts" :key="text">
      <v-skeleton-loader
        class="mx-auto border-0"
        type="paragraph"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>
<script>
import crudDataService from "../../Services/crudDataService.js";
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 3.5,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 6,
        snapAlign: 'start',
      },
    },
      items: [],
      texts: [1, 2, 3, 4,5,6],
    };
  },
  methods: {
    async sponsers() {
      let response = await crudDataService.getAll("sponsors");
      this.items = response.data;
    },
  },
  mounted() {
    this.sponsers();
  },
};
</script>
<style lang="scss">
.partenr_img {
  width: 100%;
    height: 150px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.partenr_img img{
 width: 82%;
}
.partenr_img img:hover{
 cursor: pointer;
}
.partenr_img .v-img__img--contain {
  width: 400px;
  object-fit: contain;
  margin: auto;
  right: 0 !important;
}
@media (max-width: 991.98px) {
  .partenr_img .v-img__img--contain {
    width: 115px;
  }
}

@media (min-width: 960px){
.prten .v-container {
    max-width: 1108px;
}}
@media (min-width: 1280px){
.prten .v-container {
    max-width: 1470px;

}

}

</style>
