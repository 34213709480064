<template>
      <div class="d-flex justify-space-between align-center">
        <Icons />
        <Items/>
        <Navmobile/>
      </div>
  </template>
  <script>
  import Icons from "./Icons.vue";
  import Items from "./Items.vue";
  import Navmobile from "./Navmobile.vue";
  export default {
    components: {
        Icons,
        Items,
        Navmobile
    },
  };
  </script>
  <style lang="scss" scoped></style>
  