<template>
  <div v-if="show_">
    <v-container v-if="!useritem.is_nafath_verified &&  !show_random">
      <!--    nationalId:1234567890-->
      <!--    identity_type:1-->
      <!--    //name:شركه النهضه الاسكانيه العقارية-->
      <!--    //unified_number:7009489530-->
      <div class="row">
        <div class="col-md-3">
          <label class="font-weight-bold">
            {{ $t("ID_Number") }}
          </label>
          <v-text-field
              type="number"
              variant="solo"
              class="selectnew"
              v-model="formData.nationalId"
          ></v-text-field>
        </div>
        <div class="col-md-3">
          <label class="font-weight-bold">
            {{ $t("Account_Type") }}
          </label>
          <Multiselect
              :options="optionsIdentityType"
              :searchable="true"
              :placeholder="false"
              label="name"
              class="selectnew"
              v-model="formData.identity_type"
          />

        </div>
        <div class="col-md-3" v-if="formData.identity_type == 2">
          <label class="font-weight-bold">
            {{ $t("name") }}
          </label>
          <v-text-field
              type="text"
              variant="solo"
              class="selectnew"
              v-model="formData.name"
          ></v-text-field>
        </div>
        <div class="col-md-3"  v-if="formData.identity_type == 2">
          <label class="font-weight-bold">
            {{ $t("Unified_Establishment_Number") }}
          </label>
          <v-text-field
              type="number"
              variant="solo"
              class="selectnew"
              v-model="formData.unified_number"
          ></v-text-field>
        </div>
      </div>

      <div class="text-red">{{ errorMessage ? errorMessage : "" }}</div>

      <div class="text-center">
        <button class="previewads w-50 pa-2 mt-5" @click="verifiedRequest">
          {{ $t("verified") }}
        </button>
      </div>
    </v-container>
    <v-container v-if="useritem.is_nafath_verified">
      <div class="d-flex w-100">
        <v-img
            src="../../assets/icon/approved.png"
            width="80"
            height="80"
            class=""
        ></v-img>
        <br>

      </div>
      <h5 class=" w-100 text-center">
        {{$t('is_nafath_verified_page')}}
      </h5>

    </v-container>
    <v-container v-if="show_random">

      <div class="d-flex">
        <img :src="nafathImage"
             width="80"
             height="80"
             class="image-nafath">

      </div>
      <div class="d-flex">
        <span class="number_class">{{random}}</span>
      </div>
      <div class="d-flex" >
        <a href="https://play.google.com/store/apps/details?id=sa.gov.nic.myid" style="margin: auto;text-decoration: none;font-size: 18px;font-weight: 500;color: #000;">{{$t('download_app')}}</a>
      </div>

    </v-container>
  </div>
</template>
<script>
import nafathImage from '../../assets/icon/nafath.png';
import crudDataService from "@/Services/crudDataService";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      nafathImage,
      show_: false,
      useritem: '',
      errorMessage: '',
      random: '20',
      transId: '',
      show_random: false,
      optionsIdentityType: [],
      formData: {
        name:"",
        identity_type:"1",
        nationalId:"",
        unified_number:"",
      }
    };

  },
  methods: {


    async userprof(){
      let user=await crudDataService.get('user-profile',localStorage.getItem("userId"))
      this.useritem=user.data.data
      this.show_=true
    },


    async fetchData(){
      let response=await crudDataService.get_request('nafath-status')
      if(response.data.status == 1){
        clearInterval(this.intervalId);
        window.location.reload();
      }


    },
    async get_identity_type() {

      this.optionsIdentityType = [
        { value: "1", name: this.$t("Individual") },
        { value: "2", name: this.$t("Establishment") },
      ];
    },
    async verifiedRequest() {

      await crudDataService
          .create(`nafath-request`, this.formData)
          .then((response) => {
            if(response.data.message == "failed"){
              if(response.data.error == 'Invalid Request,There Is Active Trx'){
                this.errorMessage = this.$t("Invalid Request");
              }else {
                this.errorMessage = response.data.error;
              }

            }else {
              this.random=response.data.data.random;
              this.transId=response.data.data.transId;
              this.show_random=true;
              this.intervalId = setInterval(this.fetchData, 1000);
            }

          })
          .catch((error) => {
            if (error.response) {
              this.errorMessage = error.response.data.message;
            } else if (error.request) {
              this.errorMessage = "No response from the server";
            } else {
              this.errorMessage = error.message;
            }
          });
    },

  },
  mounted() {
    this.userprof();
    this.get_identity_type();

  },

};
</script>
<style lang="scss" scoped>
.button-prof {
  width: calc(97% / 2) !important;
}


.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-top: var(--bs-gutter-y);
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
}
span.number_class {
  margin: auto;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
  padding: 10px 15px;
  font-size: 18px;
  border-radius: 50%;
  background-color: #e7e7e7a1;
}
.is_have_con{
  margin: 10px;
  padding: 10px;
  color: #fd0000;
  width: 40% !important;
  border-radius: 10px;
  background-color: #e7575761;
}
.is_not_have_con{
  margin: 10px;
  padding: 10px;
  color: #ffffff;
  width: 40% !important;
  border-radius: 10px;
  background-color: #00d37b;
}
.step_previous{
  position: absolute;
  float: left;
  left: 0;
  color: #c30000;
  font-weight: 600;
  font-size: 18px;
}
img.image-nafath {
  margin: auto;
  border-radius: 15px;
}
@media (min-width: 768px){
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
</style>
