<template>
        <Card_Ads/>
    </template>
    
    <script>
    import Card_Ads from '@/components/Single_Ads/Card_Ads.vue'
    export default {
    
      components: {
        Card_Ads
      },
    };
    </script>
    