<template>
  <v-container>
    <Provider />
  </v-container>
</template>

<script>
import Provider from "../components/Provider/Provider.vue";

export default {
  components: {
    Provider,
  },
};
</script>
