<template>
  <v-container>
    <div class="breadcrumbs my-6">
      <div
        class="font-weight-bold"
        style="font-size: 30px; cursor: pointer"
        @click="$router.go(-1)"
      >
        {{ $t("Profile") }}
      </div>
      <v-icon icon="mdi-slash-forward" style="font-size: 40px"></v-icon>
      <p style="font-size: 24px">
        {{ $t("Commission") }}
      </p>
    </div>
    <v-card class="pa-xs-5 px-2 card_update" elevation="0">
      <v-text-field
        density="compact"
        variant="solo"
        single-line
        hide-details
        :placeholder="$t('Application_commission')"
        class="rounded my-6 border-style font-weight-medium"
        v-model="amount"
      >
      </v-text-field>

      <div class="mt-md-16 mt-3">
        <button
          class="previewads rounded d-block py-2 my-16"
          style="width: 65%"
          @click="Commission"
        >
          {{ $t("Payment_commission") }}
        </button>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import crudDataService from "@/Services/crudDataService";

export default {
  data() {
    return {
     amount: '',
    };
  },
  methods: {
    async Commission() {     
      let response = await crudDataService.create(`donate`,{amount:this.amount});
      // console.log(response.data.data);
    },
  },
  mounted() {},
};
</script>
<style></style>
