<template>
    <v-container>
        <l-map :zoom="zoomout" :key="mapKey"  :center="initialCoordinates" height="300px!important" @click="get">
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      ></l-tile-layer>
      <l-marker :lat-lng="markerCoordinates"></l-marker>
      <l-marker
   key="1"
   :lat-lng="markerCoordinates"
  >
  </l-marker>
    </l-map>
    </v-container>
   
  </template>
  
  <script>
  import { ref } from "vue";
  import { LMap, LTileLayer, LMarker } from "vue3-leaflet";
  
  export default {
    data(){
      return{
        zoomout:6,
        mapKey:0,
        initialCoordinates:[24.755562,46.589584],
        markerCoordinates: [24.755562,46.589584]
      }
    },
    components: {
      LMap,
      LTileLayer,
      LMarker,
    },
    setup() {
     
    },
    methods: {
      updateMarkerCoordinates() {
        // console.log(this.markerCoordinates);
  
        const route = this.$route;
        if (route.params.param1 && route.params.param2) {
          // this.markerCoordinates[0] = parseFloat(route.params.param1);
          // this.markerCoordinates[1] = parseFloat(route.params.param2);
          let lat=parseFloat(route.params.param1);
          let lng=parseFloat(route.params.param2);
          this.markerCoordinates=[lat,lng];
          this.initialCoordinates=[lat,lng];
          this.zoomout=14;
          this.mapKey++;
          // console.log(this.zoomout);
        // console.log(this.markerCoordinates);
        // console.log(this.initialCoordinates);
  
  
     
          } else {
            // console.log("No coordinates provided");
        }
      },
      get(){
          // console.log(LMarker.props.latLng);
      }
    },
    mounted(){
      this.updateMarkerCoordinates();
    }
  };
  </script>
  <style lang="scss">
       .leaflet-container{
      height: 115vh !important;
  }
  </style>
  