import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Map from '../components/Map/Citymap.vue'
import AllINCity from '../components/Map/AllinCity.vue'
import MapAd from '../components/Map/mapinads.vue'
import RealView from '../views/RealView.vue'
import SupportServView from '../views/SupportServView.vue'
import ProviderView from '../views/ProviderView.vue'
import proviserservices from '../components/Support_services/proviserservices.vue'
import SingleAdView from '../views/SingleAdsView.vue'
import Singlesupport from '../components/Support_services/Singlesupport.vue'
import BlogView from '../views/BlogView.vue'
import AddAdView from '../views/AddAdView.vue'
import Single_blog from '../components/Blog/Single_blog.vue'
import Favorite_ads from '../components/Profile/Favorite_ads.vue'
import My_ads from '../components/Profile/My_ads.vue'
import nafath_verified from '../components/Profile/nafath_verified.vue'
import val_license from '../components/Profile/val_license.vue'
import Followers from '../components/Profile/Followers.vue'
import AllChats from '../components/Chats/AllChats.vue'
import SingleChat from '../components/Chats/SingleChat.vue'
import Blocked_Persons from '../components/Profile/Blocked_Persons.vue'
import Site_balance from '../components/Profile/Site_balance.vue'
import Account_settings from '../components/Profile/Account_settings.vue'
import Subscriptions from '../components/Profile/Subscriptions.vue'
import ChangePassword from '../components/Profile/ChangePassword.vue'
import Commission from '../components/Profile/Commission.vue'
import PrivacyView from '../views/PrivacyView.vue'
import AboutView from '../views/AboutView.vue'
import Contactview from '../views/Contactview.vue'
import AgreementView from '../views/AgreementView.vue'
import AdsconditionsView from '../views/AdsconditionsView.vue'
import TermsView from '../views/TermsView.vue'
import IdeaPolicyView from '../views/IdeaPolicyView.vue'
import SinglePartnerView from '../views/SinglePartnerView.vue'
import PartnerView from '../views/PartnerView.vue'
import ProfileView from '../views/ProfileView.vue'
import LogIn from '../views/LogIn.vue'
import Forget from '../components/ResetPassword/ForgetPassword.vue'
import Addrequest from '../components/AddAd/Addrequest.vue'
import Addoffer from '../components/AddAd/Addoffer.vue'
import ResetPassword from '../components/ResetPassword/ResetPassword.vue'
import Register from '../views/RegisterView.vue'
import Error404 from '../components/Error404/Error404.vue'
import { useAuth } from '../axios';
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/map',
    name: 'Map',
    component: Map
  },
  {
    path: '/allmap/:param1',
    name: 'AllINCity',
    component: AllINCity
  },
  {
    path: '/map/:param1/:param2',
    name: 'MapAd',
    component: MapAd
  },
  {
    path: '/real-estate',
    name: 'RealView',
    component: RealView
  },
  {
    path: '/add-new-ad',
    name: 'AddAdView',
    component: AddAdView
  },
  {
    path: '/add-request',
    name: 'Addrequest',
    component: Addrequest
  },
  {
    path: '/add-offer',
    name: 'Addoffer',
    component: Addoffer
  },
  {
    path: '/support-service',
    name: 'SupportService',
    component: SupportServView
  },
  {
    path: `/real-estate/provider/:id`,
    name: "provider",
    component: ProviderView,
  },
  {
    path: `/support-service/proviserservices/:id`,
    name: "proviserservices",
    component: proviserservices,
  },
  {
    path: `/ads/single-ads/:id`,
    name: "SingleAd",
    component: SingleAdView,
  }, 
  {
    path: `/ads/single-support/:id`,
    name: "Singlesupport",
    component: Singlesupport,
  },  
  {
    path: `/blog`,
    name: "Blog",
    component: BlogView,
  
  },  
  {
    path: `/blog/single-blog/:id`,
    name: "SingleBlog",
    component: Single_blog,
  
  },  
  {
    path: '/favorite-ads',
    name: 'Favorite_ads',
    component: Favorite_ads
  }, 
  {
    path:'/chats',
    name:'AllChats',
    component:AllChats
  },
  {
    path:'/chats/:id',
    name:'SingleChat',
    component:SingleChat
  },
  {
    path: '/followers',
    name: 'Followers',
    component: Followers
  }, 
  {
    path: '/blocked-persons',
    name: 'Blocked_Persons',
    component: Blocked_Persons
  }, 
  {
    path: '/site-balance',
    name: 'Site_balance',
    component: Site_balance
  }, 
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: Subscriptions
  }, 
  {
    path: '/account-settings',
    name: 'Account_settings',
    component: Account_settings
  }, 
  {
    path: '/account-settings/change-password',
    name: 'ChangePassword',
    component: ChangePassword
  }, 
  {
    path: '/commission',
    name: 'Commission',
    component: Commission
  }, 
  {
    path: '/my-ads',
    name: 'My_ads',
    component: My_ads
  }, 
  {
    path: '/privacy',
    name: 'Privacy',
    component: PrivacyView
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: AgreementView
  },
  {
    path: '/ads-conditions',
    name: 'Adsconditions',
    component: AdsconditionsView
  },
  {
    path: '/terms',
    name: 'Terms',
    component: TermsView
  },
  {
    path: '/idea-Policy',
    name: 'IdeaPolicy',
    component: IdeaPolicyView
  },
  {
    path: '/single-partner/:id',
    name: 'SinglePartner',
    component: SinglePartnerView
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contactview
  },
  {
    path: '/about-us',
    name: 'About',
    component: AboutView
  },
  {
    path: '/partner',
    name: 'Partner',
    component: PartnerView
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView
  },
  {
    path: '/nafath-verified',
    name: 'NafathVerified',
    component: nafath_verified
  },{
    path: '/val-license',
    name: 'valLicense',
    component: val_license
  },
  {
    path: '/sign-in',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/forget-password',
    name: 'Forget',
    component: Forget
  }, 
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
    //.....Errorpage.....
    {
      path: '/:catchAll(.*)',
      name: 'NotFound',
      component: Error404,
      meta: { hideNavFooter: true }
    },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
 
})
router.beforeEach((to, from, next) => {
  const { isLoggedIn } = useAuth();
  if (to.meta.requiresAuth && !isLoggedIn()) {
    next({ name: 'LogIn' }); // redirect to login if not authenticated
  } else {
    next(); // otherwise, proceed as normal
  }

});
export default router
