<template>
    <v-container>
        <Contact/>
    </v-container>
    </template>
    
    <script>
    import Contact from '@/components/Contact/Contact.vue'
    export default {
    
      components: {
        Contact
      },
    };
    </script>
    