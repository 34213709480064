<template>
  <section>
    <div id="mySidebar" :class="[style ? 'showing' : 'hiding', 'w3-sidebar']">
      <div class="p-3">
        <div class="w-100 text-end">
          <v-icon @click="close()" icon="mdi-close"> </v-icon>
        </div>
        <v-list class="">
        <v-list-item
    v-for="(item, index) in items"
    :key="index"
    @click="navigateTo(item.route)"
  >
    <v-list-item-title>{{$t(item.text)}}</v-list-item-title>

  </v-list-item>
    </v-list>
    <Authriz class="w-100 text-start"/>
      </div>
    </div>
   
    <v-icon icon="mdi-menu" class="icon-menu" @click="open()"></v-icon>
  </section>
</template>

<script>
import Authriz from './Authriz'

export default {
  components: {
    Authriz
  },
  data() {
    return {
      style: false,
      checksboxes: true,
      copunstype: true,
      copunsorder: true,
      copunsview: true,
      items: [
        { text: "Home", route: "/" },
        { text: "Real_estate", route: "/real-estate" },
        { text: "Support-Service", route: "/support-service" },
        { text: "Blog", route: "/blog" },
        { text: "About", route: "/about-us" },
        { text: "Contact", route: "/contact" },


      ],
    };
  },
  methods: {
    open() {
      this.style = !this.style;
    },

    close() {
      this.style = !this.style;
    },
    
  
   
  },
};
</script>
<style scoped  lang="scss">
.icon-menu{
display: none;
}

.hiding {
  display: none;
}
.w3-sidebar {
  height: 100%;
  width: 280px;
  background-color: #fff;
  position: fixed !important;
  z-index: 1111;
  overflow: auto;
  left: 0;
  top: 0;
  box-shadow: 12px 5px 40px 6px rgba(70, 87, 147, 0.08)
  
}
@media (max-width: 991.98px) {
  .showing {
  display: block;
}
    .icon-menu{
display: block;
}
.navitem{
    display: none !important;
}
}
</style>
