<template>
  <button v-if="isUserLoggedIn" @click="profile" class="d-flex align-center justify-end px-2">
    <img :src="userdata.image" alt="" v-if="userdata.image" class="companyimage">
    <v-icon icon="mdi-account" v-else></v-icon>
    <div>
      <h5 class="text-center">
        {{ userdata.name? userdata.name : $t('Profile') }}
      </h5>
      <p class="px-1 font-weight-bold text-right"  v-if="userdata.is_nafath_verified" style="display: inline-flex;font-size: 10px;background-color: rgb(231 253 239);border-radius: 5px;color: #009432;padding: 0px 5px;">


        <v-img

            class="nafath_verified"
            src="../../assets/icon/nafath-verified.svg"
        ></v-img>
        {{$t('is_nafath_verified')}}
      </p>
    </div>
    
  </button>
  <button class="px-2 d-flex align-center justify-end" @click="resigt" v-else>
    <v-icon icon="mdi-account-outline"></v-icon>
    <p>
    {{ $t("LogIn") }}
    </p>
  </button>
</template>
<script>
import { ref } from "vue";
import { useAuth } from "../../axios";
import crudDataService from "@/Services/crudDataService";
export default {
  data(){
    return{
      logined:"",
      userid:"",
      userdata:''
    }
  },
  setup() {
    const { isLoggedIn } = useAuth();
    const isUserLoggedIn = ref(isLoggedIn());
    return {
      isUserLoggedIn,
    };
  },
  components: {},
  methods: {
    async userprofile() {
      const id = localStorage.getItem("userId");
      if (id) {
      let response = await crudDataService.get(`user-profile`, id);
      this.userdata=response.data.data
      this.userid=response.data.data.id
      // console.log(this.userdata);
      }
    },
    resigt() {
      this.$router.push({ name: "LogIn" });
    },
    profile() {
      this.$router.push({ name: "Profile" });
    },
  },
  mounted() {
    this.userprofile()
    // this.logined = localStorage.getItem("authToken")?true:false
    // // console.log(this.logined);
  },
};
</script>
<style lang="scss" scoped>
.companyimage{
  width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #000;
}
.nafath_verified {
  width: 20px;
  display: inline-block;
  text-align: center;
  /* margin-top: -14px; */
}
</style>
