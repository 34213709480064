<template>
  <v-row justify="center">
    <v-col cols="12" xs="12" sm="7" md="5" class="pa-xs-3 pa-0 py-5">
      <v-card class="pa-xs-5 px-2 card_login" elevation="0">
        <v-card-title class="text-h4 weight_text">
          {{ $t("Create_account") }}
        </v-card-title>
        <p class="weight_text">
          {{ $t("welcome") }}
        </p>
        <v-card-text>
          <v-form @submit.prevent="handleRegister">
            <label class="weight_text">
              {{ $t("name") }}
            </label>
            <v-text-field
              density="compact"
              variant="solo"
              placeholder=""
              single-line
              hide-details
              class="border rounded mt-2 mb-3"
              elevation="0"
              v-model="formData.name"
            >
            </v-text-field>
            <label class="weight_text">
              {{ $t("email") }}
            </label>
            <v-text-field
              type="email"
              density="compact"
              variant="solo"
              placeholder=""
              single-line
              hide-details
              class="border rounded mt-2 mb-3"
              elevation="0"
              v-model="formData.email"
            >
            </v-text-field>
            <label class="weight_text">
              {{ $t("whatsApp") }}
            </label>
            <v-text-field
              type="tel"
              density="compact"
              variant="solo"
              placeholder="123"
              single-line
              hide-details
              class="border rounded mt-2 mb-3 tel"
              elevation="0"
              v-model="formData.whatsapp"
              @input="Validwhatsapp" 
            >
            </v-text-field>
            <div v-if="invalidat" class="text-red mb-2">
      {{ validat }}
    </div>
            <label class="weight_text">
              {{ $t("phone_number") }}
            </label>
            <v-text-field
            type="tel"
              density="compact"
              variant="solo"
              :placeholder="$t('phone_number')"
              single-line
              hide-details
              class="border rounded mt-2 mb-3 tel"
              elevation="0"
              v-model="formData.phone"
              @input="validatePhoneNumber" 
            >
            </v-text-field>
            <div v-if="invalidPhoneNumber" class="text-red mb-2">
      {{ validationMessage }}
    </div>
            <label class="weight_text">{{ $t("Password") }} </label>
            <v-text-field
              :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
              :type="visible ? 'text' : 'password'"
              density="compact"
              min="8"
              placeholder="**************"
              variant="solo"
              class="border rounded mt-2 mb-5"
              single-line
              hide-details
              @click:append-inner="visible = !visible"
              v-model="formData.password"
            ></v-text-field>
            <label class="weight_text"> {{ $t("ConfirmPassword") }} </label>
            <v-text-field
              :append-inner-icon="visible1 ? 'mdi-eye-off' : 'mdi-eye'"
              :type="visible1 ? 'text' : 'password'"
              density="compact"
              min="8"
              placeholder="**************"
              variant="solo"
              class="border rounded mt-2 mb-5"
              single-line
              hide-details
              @click:append-inner="visible1 = !visible1"
              v-model="formData.password_confirmation"
            ></v-text-field>
           
            <label class="font-weight-bold">
                {{ $t("image") }}
              </label>
            <div class="rounded border my-2" style=" height: 50px !important;">
             
              <v-file-input
                variant="solo"
                class="selectnew my-0 ps-3"
                label="Add Image"
                prepend-icon="mdi-camera"
                accept=".pdf, image/jpeg, image/png"
                @change="handleFileChange"
              ></v-file-input>
            </div>

            <div class="text-red">{{ errorMessage ? errorMessage : "" }}</div>

            <button type="submit" class="previewads d-block w-100 mt-6">
              {{ $t("Create_account") }}
            </button>
          </v-form>
          <div class="justify-center mt-6 d-flex text-h6">
            <p class="mx-1">
              {{ $t("Do_account") }}
            </p>
            <button class="font-weight-bold mx-1" @click="create()">
              <h3>
                {{ $t("LogIn") }}
              </h3>
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import crudDataService from "@/Services/crudDataService";
import { useAuth } from "../../axios";

export default {
  data() {
    return {
      formData: {
        name:"",
        email:"",
        phone: "",
        password: "",
        whatsapp:"",
        password_confirmation:"",
        image:""
      },
      errorMessage: "",
      visible: false,
      visible1: false,
      invalidPhoneNumber:false,
      invalidat:false,
      validationMessage:"",
      validat:""
    };
  },
  methods: {
    validatePhoneNumber() {
      const isNegative = this.formData.phone.startsWith('-');
      const isNumeric = /^\d+$/.test(this.formData.phone);
      this.invalidPhoneNumber = isNegative || !isNumeric;
         this.validationMessage = this.$t('enterValidPhoneNumber');
    },
    Validwhatsapp(){
      const Negative = this.formData.whatsapp.startsWith('-');
      const Numeric = /^\d+$/.test(this.formData.whatsapp);
      this.invalidat = Negative || !Numeric;
      this.validat = this.$t('enterValidwhatsapp');
 
    },
    handleFileChange(event) {
      this.formData.image = event.target.files.item(0);
    },
    async handleRegister() {
      await crudDataService
        .create(`register`, this.formData,
        {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          // // console.log(res.data.data.token, "wdjqpowjpwd");
          // localStorage.setItem("userId", res.data.data.id);
          // localStorage.setItem("authToken", res.data.data.token);
          this.$router.push({ name: "home" });
          const {isLoggedIn} = useAuth();
          isLoggedIn()
          // console.log(isLoggedIn);

        })
        .catch((error) => {
          if (error.response) {
            // The API responded with an error message
            this.errorMessage = error.response.data.message;
          } else if (error.request) {
            this.errorMessage = "No response from the server";
          } else {
            this.errorMessage = error.message;
          }
          console.error("Register error:", this.errorMessage);
        });
    },
    create() {
      this.$router.push({ name: "LogIn" });
    },
  },
};
</script>

<style scoped lang="scss">
.card_login {
  .weight_text {
    font-weight: 900;
    text-align: center;
    font-size: 16px;
  }
  .previewads {
    height: 45px;
    font-weight: 900;
  }
}
</style>
<style lang="scss">
.tel input {
    text-align: end !important;
}
</style>