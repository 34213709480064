<template>
    <section class="overflow-hidden"  v-if="items.length>0">
        <div class="text-center my-6">
      <p class="text-h4 font-weight-bold">
        {{ $t("Real_estate") }}
      </p>
      <p style="color: #00000087; font-size: 20px" class="font-weight-bold">
        {{ $t("Through_section") }}
      </p>
    </div>

    <div class="d-flex flex-wrap justify-center mt-7">
      <div class="itemreal" v-for="(item, i) in items" :key="i"  @click="providers(item.id)" style="cursor: pointer;">
        <v-img :src="item.image" class="w-100" style="height:190px"></v-img>
        <p style="font-size: 18px" class="font-weight-medium">
          {{ item.name }}
        </p>
      </div>
    </div>
    </section>
    <v-row v-else>
    <v-col cols="12" v-for="text in texts" :key="text">
      <v-skeleton-loader
        class="mx-auto border-0"
        type="paragraph"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>
<script>
import crudDataService from "@/Services/crudDataService";
import image from "../../assets/images/Rectangee.png";
export default {
  components: {},
  data() {
    return {
      items: [
      ],
      texts: [1, 2, 3, 4,5],

    };
  },
  methods: {
    async service() {
      let response = await crudDataService.getAll(`services?without=users`);
      this.items = response.data.data;
    },
    providers(id) {
      this.$router.push({ name: "provider", params: { id } });
    },
  },
  mounted() {
    this.service();
  },
};
</script>
<style lang="scss" scoped>
.itemreal {
  width: calc(100% / 6);
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 30px;
}
@media (max-width: 991.98px) {
  .itemreal {
    width: calc(100% / 4);
  }

}
@media (max-width: 700px) {
    .itemreal {
    width: calc(100% / 3);
  }
}
@media (max-width: 600px) {
    .itemreal {
    width: calc(100% / 2);
  }
}
@media (max-width: 400px) {
    .itemreal {
    width: calc(100% / 1);
  }
}
</style>
