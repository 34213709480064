<template>
    <v-container>
        <AdsCondition/>
    </v-container>
    </template>
    
    <script>
    import AdsCondition from '@/components/Adsconditions/AdsCondition.vue'
    export default {
    
      components: {
        AdsCondition
      },
    };
    </script>
    