// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader'
import { VOtpInput } from 'vuetify/labs/VOtpInput'

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides,
  components: {
    VOtpInput,
    VSkeletonLoader,
  },
}
)
