<template>
  <div class="d-flex align-center">
    <v-img
      v-if="showFirstImage"
      src="../../assets/lang/arabic.png"
      alt="Second Image"
      class="flagicon flex-0-0"
    />
    <v-img
      v-else
      src="../../assets/lang/amirican.png"
      alt="First Image"
      class="flagicon flex-0-0"
    />
    

    <button @click="toggleLanguage" class="px-2 header">
     
      {{ $t("Language") }}
    </button>
  </div>
</template>

<script>
import http from "../../http-common";
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      showFirstImage:null,
      newLocale: "ar",
      dataFromLocalStorage:''
      
    };
  },
  methods: {
    toggleLanguage() {
      this.newLocale = this.$i18n.locale === "en" ? "ar" : "en";
      this.$i18n.locale = this.newLocale;
      document.documentElement.setAttribute(
        "dir",
        this.newLocale === "ar" ? "rtl" : "ltr"
      );
      localStorage.setItem("lang", this.newLocale);
      axios.defaults.headers.common["Accept-Language"] = this.newLocale;
     
      setTimeout(() => {
        window.location.reload();
      }, 100);
      // if (localStorage.getItem('lang')=='ar') {
      //   this.showFirstImage =true;
      // }else{
      //   this.showFirstImage =false;
      // }
      this.showFirstImage = this.newLocale === "ar";
    },


   
  },
  mounted() {
    this.showFirstImage = this.$i18n.locale === "ar";
   },

 
 
};
</script>
<style lang="scss" scoped>
.flagicon {
  width: 30px;
  height: 20px;
}
</style>
