<template>
  <v-container>
    <section class="pb-12">
      <h2 class="text-center mb-2">
        {{ $t("Add_request") }}
      </h2>
      <label class="font-weight-bold">
        {{ $t("Area") }}
      </label>
      <Multiselect
        :options="optionsarea"
        :searchable="true"
        :placeholder="$t('Area')"
        label="name"
        class="selectnew"
        v-model="formData.area_id"
        @change="areachange"
      />
      <label class="font-weight-bold">
        {{ $t("City") }}
      </label>
      <multiselect
        :options="optionscity"
        :searchable="true"
        :selected="null"
        :placeholder="$t('City')"
        label="name"
        class="selectnew"
        v-model="formData.city_id"
        @change="citychange"
      ></multiselect>
      <label class="font-weight-bold">
        {{ $t("neighborhood") }}
      </label>
      <multiselect
        :options="optionsneighborhood"
        :searchable="true"
        :placeholder="$t('neighborhood')"
        label="name"
        class="selectnew"
        v-model="formData.neighborhood_id"
      ></multiselect>

      <label class="font-weight-bold">
        {{ $t("estate_type") }}
      </label>
      <Multiselect
        :options="optionsestate"
        :searchable="true"
        :placeholder="$t('estate_type')"
        label="name"
        class="selectnew"
        v-model="formData.estate_type_id"
        @change="estate($event)"
      />
      <label class="font-weight-bold">
        {{ $t("ads_type") }}
      </label>
      <Multiselect
        :options="optionsadtype"
        :searchable="true"
        :placeholder="$t('ads_type')"
        label="name"
        class="selectnew"
        v-model="formData.ad_type_id"
      />
      <label class="font-weight-bold">
        {{ $t("usage_type") }}
      </label>
      <Multiselect
        :options="optionsusagetype"
        :searchable="true"
        :placeholder="$t('usage_type')"
        label="name"
        class="selectnew"
        v-model="formData.usage_type_id"
        @change="usage($event)"
      />
      <label class="font-weight-bold">
        {{ $t("description") }}
      </label>
      <v-text-field
        variant="solo"
        class="selectnew"
        v-model="formData.description"
      ></v-text-field>
      <!-- <label class="font-weight-bold">
        {{ $t("address") }}
      </label>
      <v-text-field
        variant="solo"
        class="selectnew"
        v-model="formData.address"
      ></v-text-field> -->
      <!-- <label class="font-weight-bold">
        {{ $t("Location_Details") }}
      </label>
      <v-text-field
        variant="solo"
        class="selectnew"
        v-model="formData.location"
      ></v-text-field> -->
      <!-- <label class="font-weight-bold">
        {{ $t("lat") }}
      </label>
      <v-text-field
      type="number"
        variant="solo"
        class="selectnew"
        v-model="formData.lat"
      ></v-text-field>
      <label class="font-weight-bold">
        {{ $t("lang") }}
      </label>
      <v-text-field
      type="number"
        variant="solo"
        class="selectnew"
        v-model="formData.lng"
      ></v-text-field> -->

      <v-row>
        <v-col cols="12">
          <label class="font-weight-bold">
            {{ $t("price") }}
          </label>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <label class="font-weight-bold">
            {{ $t("from") }}
          </label>
          <v-text-field
            type="number"
            variant="solo"
            class="selectnew"
            v-model="formData.max_price"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <label class="font-weight-bold">
            {{ $t("to") }}
          </label>
          <v-text-field
            type="number"
            variant="solo"
            class="selectnew"
            v-model="formData.min_price"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <label class="font-weight-bold">
            {{ $t("Aarea") }}
          </label>
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <label class="font-weight-bold">
            {{ $t("from") }}
          </label>
          <v-text-field
            type="number"
            variant="solo"
            class="selectnew"
            v-model="formData.max_area"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <label class="font-weight-bold">
            {{ $t("to") }}
          </label>
          <v-text-field
            type="number"
            variant="solo"
            class="selectnew"
            v-model="formData.min_area"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- <label class="font-weight-bold">
        {{ $t("mortgage") }}
      </label>
      <v-text-field
        type="number"
        variant="solo"
        class="selectnew"
        v-model="formData.mortgage"
      ></v-text-field> -->

      <!-- <v-checkbox
        :label="$t('special')"
        class="check"
        @change="checkspcial"
      ></v-checkbox> -->
      <v-checkbox
        label="Story"
        class="check"
        @change="checked"
        v-if="story"
      ></v-checkbox>
      <!-- <label class="font-weight-bold">
        {{ $t("image") }}
      </label>
      <v-file-input
        variant="solo"
        class="selectnew my-2"
        v-model="formData.attachment"
        label="Add Image"
        prepend-icon="mdi-camera"
        accept=".pdf, image/jpeg, image/png"
        @change="handleFileChange"
      ></v-file-input> -->
      <v-row class="mt-2">
        <v-col cols="12" md="3" v-for="item in optionproperties" :key="item.id">
          <label class="font-weight-bold">
            {{ item.name }}
          </label>
          <input
            type="range"
            class="d-block form-control selectnew"
            :min="item.min_value"
            :max="item.max_value"
            style="border-style: solid"
            v-if="item.type == 'slider'"
            v-model="formData.options[item.id]"
          />
          <Multiselect
            v-else-if="item.type == 'multi'"
            :options="item.values_ar"
            :searchable="true"
            class="selectnew"
            v-model="formData.options[item.id]"
          />
          <v-checkbox
            v-else-if="item.type == 'switch'"
            class="check"
            v-model="formData.options[item.id]"
          ></v-checkbox>

          <v-text-field
            v-else
            :type="item.type"
            variant="solo"
            class="selectnew"
            :min="item.min_value"
            :max="item.max_value"
            v-model="formData.options[item.id]"
          ></v-text-field>

          <!-- @change="prepareAndAddData(item.id, $event, item.name)" -->
        </v-col>
      </v-row>
      <!-- <div class="d-flex align-center">
        <v-checkbox class="check d-flex flex-0-0" @change="check"></v-checkbox>
        <label @click="dialog = true">
          <h3 style="cursor: pointer">
            {{ $t("Conditions_advertisement") }}
          </h3>
        </label>
      </div> -->
      <div class="text-red">{{ errorMessage ? errorMessage : "" }}</div>

      <div class="text-center">
        <button class="previewads w-50 pa-2 mt-5" @click="addnewad">
          {{ $t("Add_new_ad") }}
        </button>
      </div>
      <v-dialog v-model="dialog" width="700">
        <v-card class="text-center py-5">
          <h3
            class="w-max-content mx-auto rounded pa-1 text-h5 font-weight-bold bg-yellow-darken-1"
          >
            {{ $t("Conditions_advertisement") }}
          </h3>
          <p class="pa-5 text-h6">
            هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا
            النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد
            من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.إذا
            كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد
            الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص
            العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج العميل فى كثير
            من الأحيان أن يطلع على صورة حقيقية لتصميم الموقع.ومن هنا وجب على
            المصمم أن يضع نصوصا مؤقتة على التصميم ليظهر للعميل الشكل كاملاً،دور
            مولد النص العربى أن يوفر على المصمم عناء البحث عن نص بديل لا علاقة
            له بالموضوع الذى يتحدث عنه التصميم فيظهر بشكل لا يليق.هذا النص يمكن
            أن يتم تركيبه على أي تصميم دون مشكلة فلن يبدو وكأنه نص منسوخ، غير
            منظم، غير منسق، أو حتى غير مفهوم. لأنه مازال نصاً بديلاً ومؤقتاً.
          </p>
        </v-card>
      </v-dialog>
    </section>
    <v-snackbar
      v-model="showSnackbar"
      color="yellow-darken-2
"
    >
      {{ $t("Added_successfully") }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import crudDataService from "@/Services/crudDataService";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      showSnackbar: false,
      errorMessage: "",
      eventchange: "",
      dialog: false,
      optionscity: [],
      optionsarea: [],
      optionsneighborhood: [],
      optionsestate: [],
      optionsadtype: [],
      optionsusagetype: [],
      nei_id: "",
      areaid: "",
      formData: {
        city_id: "",
        neighborhood_id: "",
        area_id: "",
        estate_type_id: "",
        ad_type_id: "",
        usage_type_id: "",
        main_type: "buy",
        description: "",
        min_price: "",
        max_price: "",
        min_area: "",
        max_area: "",
        usage_id: "",
        estate_id: "",
      },
      optionproperties: [],
      story: true,
    };
  },
  methods: {
    async getstories() {
      let response = await crudDataService.getAll("stories");
      if (response.data.data.ads[0]) {
        this.story = response.data.data.ads[0].is_story == 1 ? false : true;
        // console.log(this.story);
      }
    },
    handleFileChange(event) {
      this.formData.attachment[0] = event.target.files.item(0);
    },
    checked(e) {
      if (e.target.checked) {
        this.formData.is_story = 1;
      } else {
        this.formData.is_story = 0;
      }
    },
    check(e) {
      if (e.target.checked) {
        this.formData.estate_notes = 1;
      } else {
        this.formData.estate_notes = 0;
      }
    },
    checkspcial(e) {
      if (e.target.checked) {
        this.formData.special = 1;
      } else {
        this.formData.special = 0;
      }
    },
    async Areas() {
      let response = await crudDataService.getAll(`areas`);
      this.optionsarea = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },

    areachange(e) {
      this.areaid = e;
      this.City();
    },

    async City() {
      let response = await crudDataService.getAll(
        `cities?area_id=${this.areaid}`
      );
      this.optionscity = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },
    citychange(e) {
      this.nei_id = e;
      this.neighborhood_id();
    },
    async neighborhood_id() {
      let response = await crudDataService.getAll(
        `neighborhoods?city_id=${this.nei_id}`
      );
      this.optionsneighborhood = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
      // console.log(response.data.data);
    },
    async estate_types() {
      let response = await crudDataService.getAll(`estate-types`);
      this.optionsestate = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },
    async ad_types() {
      let response = await crudDataService.getAll(`ad-types`);
      this.optionsadtype = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },
    async usage_types() {
      let response = await crudDataService.getAll(`usage-types`);
      this.optionsusagetype = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },

    prepareAndAddData(id, event, name) {
      this.formData.options.push({
        property_id: id,
        name: name,
        values: event,
      });
    },
    estate(e) {
      this.estate_id = e;
      // console.log(e);
    },
    usage(e) {
      this.usage_id = e;
      // console.log(e);
      //   this.properties();
    },
    async properties() {
      let response = await crudDataService.getAll(
        `properties/${this.estate_id}/${this.usage_id}`
      );
      //   // console.log(response.data.data);
      this.optionproperties = response.data.data;
      // console.log(this.optionproperties);
    },
    async addnewad() {
      // console.log("formdata", this.formData);
      // console.log(this.formData.options);
      let response = await crudDataService
        .create(`store-ad`, this.formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.showSnackbar = true;
          setTimeout(() => {
            this.$router.push("/");
          }, 5000);
        })
        .catch((error) => {
          if (error.response) {
            // The API responded with an error message
            this.errorMessage = error.response.data.message;
          } else if (error.request) {
            this.errorMessage = "No response from the server";
          } else {
            this.errorMessage = error.message;
          }
        });
    },
  },
  mounted() {
    this.Areas();
    this.estate_types();
    this.ad_types();
    this.usage_types();
    this.getstories();
  },
};
</script>

<style lang="scss">
input[type="range"] {
  accent-color: var(--primary-color);
}
.selectnew {
  &.multiselect {
    margin-top: 8px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .multiselect-search {
    border-radius: 10px;
  }
}
.selectnew.v-input--density-default .v-field--variant-outlined,
.selectnew.v-input--density-default .v-field--single-line,
.selectnew.v-input--density-default .v-field--no-label {
  box-shadow: none !important;
  border: 1px solid #d1d5db !important;
  border-radius: 10px !important;
  margin-top: 6px;
  margin-bottom: 8px;
}
.check .v-input__details,
.selectnew .v-input__details {
  display: none !important;
}
.selectnew.v-text-field input.v-field__input {
  min-height: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
}
</style>
