import axios from "axios";
const authToken = localStorage.getItem('authToken');
const lang=localStorage.getItem('lang');
// console.log(lang);
export function useAuth() {
  const isLoggedIn = () => {
    return !!authToken;
  };

  const setToken = (token) => {
    localStorage.setItem('authToken', token);
    authToken = token;
  };

  const clearToken = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem(localStorage.getItem('userId'));
    // authToken = "";
  };

  return { isLoggedIn, setToken, clearToken };
}
// console.log(lang);

axios.defaults.headers.common["Accept-Language"] = lang;
var domain = "https://dev.aqarcom.sa/api";
axios.defaults.baseURL = domain;
if (domain == "https://dev.aqarcom.sa/api") {
  axios.defaults.token =`Bearer ${authToken}`
} else {
  axios.defaults.token;
}