<template>
<v-container >
   <section v-if ="Object.entries(items).length>0 ">
    <div class="text-center my-6" >
      <p class="text-h4 font-weight-bold">
        {{ $t("Blog") }}
      </p>
      <p style="color: #00000087; font-size: 20px" class="font-weight-bold">
        {{ $t("Through_section") }}
      </p>
    </div>

    <div class="d-flex  flex-column">
     
        <img :src="items.image" class="rounded" style="height: 350px;object-fit: contain;" alt="" />
     
        <div class="pa-7">
          <div class="d-flex flex-lg-row flex-column justify-space-between">
            <h3 class="font-weight-bold">
              {{ items.title }}
            </h3>
            <p class="text-end">
              <v-icon icon="mdi-clock-time-five-outline"></v-icon>
              {{ items.date }}
            </p>
          </div>
          <p class="">
            {{ items.description }}
          </p>
        </div>
      </div>
   </section>
    
      <v-row v-else >
  
  <v-col cols="12" >
    <v-skeleton-loader
      class="mx-auto border-0 "
      type="image,paragraph"
    ></v-skeleton-loader>
  </v-col>
  <v-col cols="12" >
    <v-skeleton-loader
      class="mx-auto border-0 "
      type="image,paragraph"
    ></v-skeleton-loader>
  </v-col>

</v-row>
</v-container>

</template>

<script>
import crudDataService from "@/Services/crudDataService";
export default {
  data() {
    return {
      items: [],
      itemsPerRow: 3,
    };
  },

  methods: {
    async singleblog() {
      let response = await crudDataService.get(
        "blogs",
        `${this.$route.params.id}`
      );
      this.items = response.data.data;
      // console.log(this.items);
    },
  },
  mounted() {
    this.singleblog();
  },
};
</script>

<style scoped></style>
