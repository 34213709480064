<template>
  <v-container>
    <section v-if="items">
    <div class="text-center text-h3" >
    <h5>
        {{ $t('chat') }}
    </h5>
    </div>
    <v-row v-for="item in items " :key="item.id" class="align-center" @click="singlechats(item.receiver_id)" style="cursor:pointer">
        <v-col cols="2" class="text-center">
            <img :src="item.receiver.image" alt="" class=" rounded-circle" style="width:120px;height:120px">
        </v-col>
        <v-col cols="10">
            <h6 class="font-weight-bold text-h5" >
                {{ item.title }}
            </h6>
            <p class="mb-4 ml-6 text-grey-darken-1 font-weight-medium">{{ item.messages[0].message }}</p>
        </v-col>
    </v-row>
   

    </section>
    <v-row v-else >
      
      <v-col cols="12" v-for="text in texts" :key="text">
        <v-skeleton-loader
          class="mx-auto border-0 "
          type="paragraph"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import crudDataService from "@/Services/crudDataService";

export default {
    data() {
    return {
      items: "",
      texts:[1,2,3,4]
    };
  },

  methods: {
    async getchats() {
      let response = await crudDataService.getAll("chats/index");
      // console.log(response.data.data);
      this.items = response.data.data;
    },
    singlechats(id){
        this.$router.push({name:'SingleChat', params:{ id }})
    }
  },
  mounted() {
    this.getchats();
  },
};
</script>
<style lang=""></style>
