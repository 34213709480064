<template>
  <section>
    <section v-if="items">
      <v-container>
        <div class="text-center text-h3">
          <h5 class="my-3">
            {{ $t("chat") }}
          </h5>
        </div>
        <div class=" mb-16">
          <div
            v-for="(item, i) in items"
            :key="item.sender_id"
            class="w-100"
          >
            <div  
            class="mb-7 pa-2 w-100"
            >
            <p 
            class="rounded pa-2"
            style="background-color: #d9d9d9; width: max-content"
            :class="isIdSameAsNext(i)">
                {{ item.message }}
            </p>
                
              </div>
          </div>
        </div>
      </v-container>
      <div style="background: #f6c712" class="mt-12 pt-4">
      <v-container>
        <form @submit.prevent="sendMessage()" class="d-flex align-center">
          <v-text-field
            variant="solo"
            class="sendmsg"
            v-model="message"
            :placeholder="$t('Write_message')"
          ></v-text-field>
          <v-btn
            type="submit"
            class="bg-white font-weight-bold text-h6 pb-2"
            style="width: 150px"
          >
            {{ $t("Send") }}
          </v-btn>
        </form>
      </v-container>
    </div>
    </section>
    <v-container v-else>
      <v-row>
        <v-col cols="12" v-for="text in texts" :key="text">
          <v-skeleton-loader
            class="mx-auto border-0"
            type="paragraph"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    
  </section>
</template>
<script>
import crudDataService from "@/Services/crudDataService";

export default {
  data() {
    return {
      items: "",
      texts: [1, 2, 3, 4,5],
      message: "",
    };
  },

  methods: {
    async getsingle() {
      let response = await crudDataService.get(
        "chats",
        `${this.$route.params.id}`
      );
      // console.log(response.data.data);
      this.items = response.data.data;
      this.items.reverse();
    },
    isIdSameAsNext(index) {

      const currentId = this.items[0].sender_id;
      const nextId = this.items[index].sender_id
      if (currentId === nextId) {
        return   console.log(currentId === nextId);
      }
      return `specialStyle`
       
    },
    async sendMessage() {
      let res = await crudDataService.create("chats/send-message", {
        receiver_id: `${this.$route.params.id}`,
        message: this.message,
      });
      // console.log(res);
      this.getsingle();
    },
  },
  mounted() {
    this.getsingle();
  },
};
</script>
<style lang="scss">
.specialStyle {
    float: left;
  background: #f6c7128c !important;
}
.sendmsg.v-input--density-default .v-field--variant-outlined,
.sendmsg.v-input--density-default .v-field--single-line,
.sendmsg.v-input--density-default .v-field--no-label {
  box-shadow: none !important;
  border: 1px solid #d1d5db !important;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
}
.check .v-input__details,
.sendmsg .v-input__details {
  display: none !important;
}
.sendmsg.v-text-field input.v-field__input {
  min-height: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
}
</style>
