<template>
    <v-container>
      <Support_services />
    </v-container>
  </template>
  
  <script>
  import Support_services from "../components/Support_services/Support_services.vue";
  
  export default {
    components: {
        Support_services,
    },
  };
  </script>
  