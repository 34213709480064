<template>
  <v-container>
    <l-map
      :zoom="zoomout"
      :key="mapKey"
      :center="initialCoordinates"
      height="300px!important"
      :options="mapOptions"
    >
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      ></l-tile-layer>
      <l-marker   v-for="(marker,i) in markers" 
      :key="i" 
      :lat-lng="marker.latLng"
      @click="single(marker.id)"
     >
     
        <!-- <l-popup  @click="single(marker.id)" >
            {{ marker.price }}
        </l-popup> -->
        <l-icon
          :icon-anchor="staticAnchor"
          class-name="someExtraClass"
        >
          <div class="headline " >
            {{ marker.price }}
          </div>
          
        </l-icon>
      </l-marker>
        </l-map>
  </v-container>
</template>

<script>
import crudDataService from "@/Services/crudDataService";
import { ref } from "vue";
import { latLng, icon } from "leaflet";
// import { LIcon  } from "@vue-leaflet/vue-leaflet";
// import { LMap, LTileLayer, LMarker,LPopup } from "vue3-leaflet";
import { LMap, LTileLayer, LMarker,LTooltip,LIcon,LPopup  } from "@vue-leaflet/vue-leaflet";

export default {
  data() {
    return {
      zoomout: 10,
      mapKey: 0,
      mapOptions: {
        zoomControl: true,
        scrollWheelZoom: true,
        touchZoom: true,
        doubleClickZoom: true,
        boxZoom: false
      },
      initialCoordinates: [24.755562, 46.589584],
      markerCoordinates: [24.755562, 46.589584],
      markers:[],
      icon: icon({
        iconUrl: "static/images/baseball-marker.png",
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      staticAnchor: [16, 37],
    };
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon
    
  },
  setup() {},
  methods: {
    single(id){
      this.$router.push({ name: 'SingleAd', params:{ id } })
    },
    async getallmark() {
      const route = this.$route;
      if (route.params.param1 ) {
        let res = await crudDataService.getAll(
          `ads?state_id=${route.params.param1}`
        );
        // Add markers from the 'nearest' array
        // console.log(res.data.data);
        res.data.data.sell.nearest.forEach((element) => {
          // // console.log(element); // Logging each element
          this.markers.push({
            latLng: [element.lat, element.lng],
            lat: element.lat,
            Lng: element.lng,
            price: element.price,
            id: element.id,
            popupOpen: true,
          });
        });

        // Add markers from the 'latest' array
        res.data.data.sell.latest.forEach((element) => {
          this.markers.push({
            latLng: [element.lat, element.lng],
            price: element.price,
            id: element.id,
            popupOpen: true,
          });
        });
      }
    },
   
    get() {
      // console.log(LMarker.props.latLng);
    },
  },
  mounted() {
    this.getallmark();
  },
};
</script>
<style lang="scss">
.map-tooltip {
    z-index: 555555;
  position: absolute;
  background-color: white;
  border: 1px solid black;
  padding: 4px;
  pointer-events: none; /* Make sure the tooltip doesn't interfere with map interaction */
  /* Add more styling as needed */
}
.leaflet-container {
  height: 115vh !important;
}
</style>
