<template>
    <v-container>
        <Agreement/>
    </v-container>
    </template>
    
    <script>
    import Agreement from '@/components/Agreement/Agreement.vue'
    export default {
    
      components: {
        Agreement
      },
    };
    </script>
    