<template>
    <v-container>
        <AddAds/>
    </v-container>
    </template>
    
    <script>
    import AddAds from '@/components/AddAd/AddAds.vue'
    export default {
    
      components: {
        AddAds
      },
    };
    </script>
    