<template>
    <section class="overflow-hidden" v-if="items.length>0">
        <div class="text-center my-6">
      <p class="text-h4 font-weight-bold py-2">
        {{ $t("Support-Service") }}
      </p>
      <p style="color: #00000087; font-size: 20px" class="font-weight-bold py-2">
        {{ $t("Through_section") }}
      </p>
    </div>

    <div class="d-flex flex-wrap justify-start pt-7 pb-12">
      <div class="itemreal" v-for="(item, i) in items" :key="i" @click="providers(item.id)">
          <img :src="item.image">
        <!-- <v-img :src="item.image" class="icon_service w-100" style="height:190px"></v-img> -->
        <p style="font-size: 20px" class="font-weight-medium text-center my-2 text-white">
          {{ item.title }}
        </p>
      </div>
    </div>
    </section>
    <v-row v-else>
    <v-col cols="12" v-for="text in texts" :key="text">
      <v-skeleton-loader
        class="mx-auto border-0"
        type="paragraph"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>
<script>
import crudDataService from "@/Services/crudDataService";
export default {
  components: {},
  data() {
    return {
      items: [
      ],
      texts: [1, 2, 3, 4,5],

    };
  },
  methods: {
    async service() {
      let response = await crudDataService.getAll(`support-services?without=users`);
      this.items = response.data.data;
    },
    providers(id) {
      this.$router.push({ name: "proviserservices", params: { id } });
    },
  },
  mounted() {
    this.service();
  },
};
</script>
<style>
    
.icon_service .v-img__img--contain{
object-fit: none !important;
}
</style>
<style lang="scss" scoped>
.itemreal {
  width: calc(85% / 4);
  padding: 0;
  margin-bottom: 30px;
  cursor: pointer;
  border: 2px solid var(--primary-color);
  border-radius: 9px;
  margin: 15px;
  text-align: center;
  overflow: hidden;
  height: 200px;
  position: relative;
}
.itemreal img{
  width: 100%;
  height: 100%;
  // transition: all 0.4s;
  filter: brightness(0.5);
}
.itemreal p{
  position: absolute;
  bottom:5px ;
  left: 25%;
    right: 20%;

}
.itemreal:hover img{
  // transform: scale(1.15) rotate(0.01deg);
}
@media (max-width: 991.98px) {
  .itemreal {
    width: calc(100% / 4);
  }

}
@media (max-width: 700px) {
    .itemreal {
    width: calc(100% / 3);
  }
}
@media (max-width: 600px) {
    .itemreal {
    width: calc(100% / 2);
    p{
    font-size: 12px !important;
    }
  }
}

</style>
