<template>
  <v-row justify="center">
    <v-col cols="12" xs="12" sm="7" md="5" class="pa-xs-3 pa-0 py-5">
      <v-card class="pa-xs-5 px-2 card_login" elevation="0">
        <v-card-title class="text-h4 weight_text">{{ $t('LogIn') }} </v-card-title>
        <p class="weight_text">
          {{ $t('welcome') }}
        </p>
        <v-card-text>
          <v-form @submit.prevent="handleLogin">
            <label class="weight_text">
              {{ $t('phone_number') }}
             </label>
            <v-text-field
            type="tel"
              density="compact"
              variant="solo"
              :placeholder="$t('phone_number')"
              single-line
              hide-details
              class="border rounded mt-2 mb-6 tel"
              elevation="0"
              v-model="loginForm.phone"
              @input="validatePhoneNumber" 
            >
            </v-text-field>
            <div v-if="invalidPhoneNumber" class="text-red mb-2">
      {{ validationMessage }}
    </div>
            <label class="weight_text"> 
              {{ $t('Password') }}
             </label>
          
            <v-text-field
        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible ? 'text' : 'password'"
        placeholder="**************"
        density="compact"
        class="border rounded mt-2 mb-6"
        variant="solo"
        single-line
              hide-details
        @click:append-inner="visible = !visible"
        v-model="loginForm.password"
      ></v-text-field>
            <div class="text-red">{{ errorMessage?errorMessage:""}}</div>
            <div
              class="d-flex flex-sm-row flex-column justify-space-between align-sm-center align-start"
            >
              <v-checkbox :label="$t('remember')" class="mt-0" hide-details></v-checkbox>
              <p class="weight_text" elevation="0" @click="()=>{$router.push('/forget-password') }">
               {{ $t('ForgetPassword') }}
                </p>
            </div>
            <button type="submit" class="previewads d-block w-100">
              {{ $t('LogIn') }} 
            </button>
          </v-form>
          <div class="justify-center mt-4 d-flex text-h6">
            <p class="mx-1">
           {{ $t('Dont_account') }}
          </p>
            <button class="font-weight-bold mx-1" @click="create()">
              <h3>
                {{ $t('Create_account') }}
              </h3>
            </button>
          </div>
         
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import crudDataService from "@/Services/crudDataService";

export default {
  data() {
    return {
      loginForm: {
        phone: "",
        password: "",
        device_token:"1234"
      },
      errorMessage: "",
      visible: false,
      invalidPhoneNumber:false,
      validationMessage:""
    };
  },
  methods: {
    validatePhoneNumber() {
      // Simple check to ensure the phone number does not start with a negative sign
      // and contains only digits. You can replace this with a more robust validation if needed.
      const isNegative = this.loginForm.phone.startsWith('-');
      const isNumeric = /^\d+$/.test(this.loginForm.phone);

      this.invalidPhoneNumber = isNegative || !isNumeric;

      // If you have a localization library like vue-i18n, you can use it to switch messages
      this.validationMessage = this.$t('enterValidPhoneNumber');
    },
    async handleLogin() {
       try {
    const response = await crudDataService
      .create(`login`, this.loginForm)
      this.$router.push({ name: 'home' })
      .then(() => {
      localStorage.setItem('userId', response.data.data.id);
      localStorage.setItem('authToken', response.data.data.token);
      window.location.reload();
    });
  }catch(error)  {
          // console.log(error.request);
          // console.log(error.response);
          // console.log(error.response.data.message);
          if (error.response) {
            // The API responded with an error message
            this.errorMessage = error.response.data.message;
          } else if (error.request) {
            this.errorMessage = 'No response from the server';
          } else {
            this.errorMessage = error.message;
          }
          console.error('Login error:', this.errorMessage);
        }
    },
    create(){
      this.$router.push({name:'Register'})
    }
  },
};
</script>
<style lang="scss">
.card_login {
  .v-field--variant-solo,
  .v-field--variant-solo-filled {
    box-shadow: none;
  }
}
.tel input {
    text-align: end !important;
}
</style>
<style scoped lang="scss">
.card_login {
  .weight_text {
    font-weight: 900;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
  }
  .previewads {
    height: 45px;
    font-weight: 900;
  }
}
</style>
