<template>
    <v-container>
      <Blog />
    </v-container>
  </template>
  
  <script>
  import Blog from "../components/Blog/Blog.vue";
  
  export default {
    components: {
        Blog,
    },
  };
  </script>
  