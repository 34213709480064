<template>
    <v-container v-if="items.length>=1">
        <div class="breadcrumbs my-5">
      <div
        class="font-weight-bold"
        style="font-size: 30px; cursor: pointer"
        @click="$router.go(-1)"
      >
        {{ $t("Profile") }}
      </div>
      <v-icon icon="mdi-slash-forward" style="font-size: 40px"></v-icon>
      <p  style="font-size: 24px; ">
        {{ $t("Favorite_ads") }}
</p>
    </div>
  
      <v-row class="pt-4">
        <v-col cols="12" md="4" v-for="(ad, i) in items" :key="i">
          <Card
            :image="ad.attachment[0]"
            :title="ad.price"
            :subtitle="ad.estate_type + ad.ad_type"
            :city="ad.city"
            :date="ad.publish_date"
            :type="ad.estate_type"
            :numwidth="options"
          ></Card>
        </v-col>
      </v-row>
    </v-container>

  <v-container  v-else-if="items.length == 0" class="my-12 py-10">
    <v-alert
   
   variant="outlined"
   type="warning"
   prominent
   border="top"
   class="mt-12  mb-12 "
 >
 {{ $t('There_is_no_data') }}
 </v-alert>
  </v-container>
  
    <v-container v-else>
      <v-row>
        <v-col cols="12" v-for="text in texts" :key="text">
          <v-skeleton-loader
            class="mx-auto border-0"
            type="paragraph"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  import Card from "../Card/Card.vue";
  import crudDataService from "@/Services/crudDataService";
  
  export default {
    components: {
      Card,
    },
    data() {
      return {
        items: [],
        options: [],
      texts: [1, 2, 3, 4,5],
        listArray:[]
      };
    },
    methods: {
      async favAds() {
        let response = await crudDataService.getAll(`favourite-ads`);
        // console.log(response)
        this.listArray = response.data.data;
       this.items=this.listArray.slice(0, 3)
        this.items.forEach((element) => {
          this.options = element.options;
        });
      },
    },
    mounted() {
      this.favAds();
    },
  };
  </script>
  <style></style>
  