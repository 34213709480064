<template>
    <v-container>
      <Register />
    </v-container>
  </template>
  
  <script>
  import Register from "../components/Register/Register.vue";
  export default {
    components: {
        Register,
    },
  };
  </script>
  