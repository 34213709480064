<template>
  <v-app>
    <v-main>
      <v-container v-if="showNavFooter">
      <Navbar />
    </v-container>
    <v-divider></v-divider>
    <v-container v-if="showNavFooter">
      <Navitem />
    </v-container>
    <v-divider></v-divider>
    <router-view />
    <Footer v-if="showNavFooter"/>
    <WhatsApp/>
    </v-main>
  </v-app>
</template>

<script>

import WhatsApp from './components/WhatsApp/WhatsApp.vue';
import Navbar from './components/Navbar/Navbar.vue';
import Navitem from './components/Navitem/Navitem.vue';
import Footer from './components/Footer/Footer.vue';
export default {
  name: "App",
  data: () => ({}),
  components: {
    Navbar,
    Navitem,
    Footer,
    WhatsApp
  },
  computed: {
    showNavFooter() {
      // Replace 'NotFound' with the name of your 404 route
      return this.$route.name !== 'NotFound';
    }
  }
};
</script>
