<template>
  <section v-if="user">
    <v-container>
      
      <!-- <div
          style="
          position: fixed;
    right: 10%;
    bottom:10%;
    z-index: 555;
    cursor: pointer;
          "
        >
          <a :href="`tel:+${user.phone}`" class="text-black iconssocials"
        >
            <v-icon icon="mdi-phone" class="mx-1"></v-icon
          ></a>
  
          <v-icon icon="mdi-forum-outline" class="iconssocials" @click="navigateTo('/chats')"></v-icon>
          <a
            :href="`https://api.whatsapp.com/send?phone=${user.whatsapp}`"
            target="_blank"
            class="text-black iconssocials"
          >
            <v-icon icon="mdi-whatsapp" class="mx-1"></v-icon>
          </a>
          <v-icon
            icon="mdi-block-helper"
            @click="dialog = true"
            class=" iconssocials"
          ></v-icon>
        </div> -->
      <v-dialog v-model="dialog" width="500">
        <v-card class="text-center py-5">
          <img :src="warning" width="100" class="mx-auto" alt="" />
          <h3>
            {{ $t("Do_block_advertiser") }}
          </h3>
          <div class="d-flex justify-center align-center mt-2">
            <button
              @click="blocked(user.id)"
              class="previewads pa-1 mx-1 rounded"
              style="width: 100px"
            >
              {{ $t("yes") }}
            </button>
            <button
              @click="dialog = false"
              class="border rounded text-black pa-1 mx-1"
              style="width: 100px"
            >
              {{ $t("no") }}
            </button>
          </div>
        </v-card>
      </v-dialog>

      <v-card-title class="text-center text-md-h4 text-h5 font-weight-bold">{{
        $t("Preview_ad")
      }}</v-card-title>
      <v-card class="mx-auto" elevation="0">
        <div>
          <vue-picture-swipe :items="itemsimage"></vue-picture-swipe>
      <!-- <div class="d-flex justify-center">
          <div class="watermarked"  v-for="(add, i) in addetail.attachment"
          :key="i">
          <img
          class="mx-2"
          :src="add"

          style="object-fit: cover;"
        />
</div>
</div> -->

  </div>
       
      
       
        <!-- <v-img :src="add" cover> </v-img> -->

        <v-card-title
          class="mx-auto bg-yellow-darken-1 mt-2 rounded pa-2 pt-1 text-h5 font-weight-medium"
          style="width: max-content"
        >
          <span class="mx-1">
            {{ addetail.estate_type }} {{ ' ' }}  {{ addetail.ad_type }}
          </span>
         
        </v-card-title>
        <div
          class="d-flex flex-md-row flex-column justify-space-between align-start my-5"
        >
          <div class="text-h6">
            <h1>
              {{ addetail.price.split('.')[0] }} 
              {{ $t("SR") }}
            </h1>
            <div class="text-grey-darken-1">
              <span class="mx-1">
                {{ addetail.estate_type }} {{ ' ' }}  {{ addetail.ad_type }}

              </span>
             
            </div>
            <v-card-title class="pa-0">
              <v-icon icon="mdi-map-marker-outline"></v-icon>
              {{ addetail.city }}
            </v-card-title>
          </div>
          <div class="text-h5 font-weight-medium text-grey-darken-1">
            {{ addetail.publish_date }}
          </div>
        </div>
        <div
          class="bg-blue-grey-lighten-5 w-100 rounded d-flex flex-wrap justify-lg-start justify-center"
        >
          <div
            class="bg-blue-grey-lighten-4 ma-2 pa-2 rounded d-flex flex-column justify-space-between"
            v-for="(prop, i) in addetail.properties"
            style="width: 100px; height: 95px"
            :key="i"
          >
            <img :src="prop.image" style="width: 25px" :alt="prop.name" />
            <p>
              {{ prop.name }}
            </p>
            <div class="px-1" v-for="(ele, i) in prop.values" :key="i">
              <div v-if="typeof ele === 'object'">
                <p>
                  {{
                    ele[0] === "true"
                      ? "نعم"
                      : ele[0] === "false"
                      ? "لا"
                      : ele[0] === "0"
                      ? ele[0]
                      : ele[0]
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-md-row flex-column justify-space-between align-start my-5"
        >
          <div class="text-h5">
            <h3 class="mb-2">
              {{ $t("Ad_Details") }}
            </h3>
            <div class="text-grey mb-2">
              <label class="d-inline"> {{ $t("Expiry_date") }}: </label>
              <p class="d-inline">
                {{ addetail.end_date }}
              </p>
            </div>
            <div class="text-grey mb-2">
              <label class="d-inline"> {{ $t("View") }}: </label>
              <p class="d-inline">
                {{ addetail.visits_count }}
              </p>
            </div>


            <div class="text-grey mb-2">
              <label class="d-inline"> {{ $t("Val_number") }}: </label>
              <p class="d-inline">
                {{ addetail.delegation_number }}
              </p>
            </div>
            <div class="text-grey mb-2">
              <label class="d-inline"> {{ $t("Advertising_number") }}: </label>
              <p class="d-inline">
                {{ addetail.advertiser_registration_number }}
              </p>
            </div>
            <h3 class="mb-2">
              {{ $t("Ad_description") }}
            </h3>
            <div class="text-grey mb-2">
              {{addetail.description}}
            </div>
          </div>
          <div class="text-h5">
            <h3 class="mb-2">
              {{ $t("Location_Details") }}
            </h3>
            <div class="text-grey mb-2">
              <label class="d-inline"> {{ $t("Area") }}: </label>
              <p class="d-inline">
                {{ addetail.estate_area }}
              </p>
            </div>
            <div class="text-grey mb-2">
              <label class="d-inline"> {{ $t("City") }}: </label>
              <p class="d-inline">
                {{ addetail.city }}
              </p>
            </div>
            <div class="text-grey mb-2">
              <label class="d-inline"> {{ $t("neighborhood") }}: </label>
              <p class="d-inline">
                {{ addetail.neighborhood }}
              </p>
            </div>
            <button
              @click="gotomap(addetail.lat, addetail.lng)"
              class="text-black rounded py-2 px-3 bg-yellow-darken-2"
            >
              {{ $t("Show_themap") }}
            </button>
          </div>
        </div>
      </v-card>
      <h1>
        {{ $t("Public_services") }}
      </h1>
    </v-container>

    <v-list class="d-flex align-center listtype pa-2">
      <v-list-item
        v-for="(item, i) in serv"
        :key="i"
        @click="providers(item.id)"
        class="text-center"
      >
        <img :src="item.image" style="width: 70px; height: 70px" />
        <!-- <v-img></v-img> -->
        <p style="font-size: 20px" class="font-weight-medium text-center my-2">
          {{ item.name }}
        </p>
      </v-list-item>
    </v-list>
    <v-container>
      <h1>
        {{ $t("Advertiser_data") }}
      </h1>
      <v-row class="my-4 align-center">
        <v-col cols="1" md="1">
          <div
          style="
   
    cursor: pointer;
          "
        >
          <a :href="`tel:+${user.phone}`" class="text-black iconssocials"
        >
            <v-icon icon="mdi-phone" class="mx-1"></v-icon
          ></a>
  
          <v-icon icon="mdi-forum-outline" class="iconssocials" @click="navigateTo('/chats')"></v-icon>
          <a
            :href="`https://api.whatsapp.com/send?phone=${user.whatsapp}`"
            target="_blank"
            class="text-black iconssocials"
          >
            <v-icon icon="mdi-whatsapp" class="mx-1"></v-icon>
          </a>
          <v-icon
            icon="mdi-block-helper"
            @click="dialog = true"
            class=" iconssocials"
          ></v-icon>
        </div>
        </v-col>
        <v-col cols="5" md="2">
          <img :src="user.image" class="w-100" />
        </v-col>
        <v-col cols="6" md="9">
          <h1>
            {{ user.name }}
            <v-icon
              icon="mdi-check-decagram"
              v-if="user.is_nafath_verified"
            ></v-icon>
          </h1>
        </v-col>
      </v-row>
    </v-container>
  </section>
  <v-container v-else>
    <v-row>
      <v-col cols="12" v-for="text in texts" :key="text">
        <v-skeleton-loader
          class="mx-auto border-0"
          type="paragraph"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VuePictureSwipe from 'vue-picture-swipe';

import imgwarn from "../../assets/icon/warning.png";
import crudDataService from "@/Services/crudDataService";
export default {
  components: {
    VuePictureSwipe
  },
  data() {
    return {
      dialog: false,
      addetail: "",
      items: [],
      serv: [],
      user: "",
      warning: imgwarn,
      texts: [1, 2, 3, 4, 5],
      alert: false,
      itemsimage: [
        
      ]
    
    };
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
    async blocked(id) {
      await crudDataService.get(`user-block`, id).then((res) => {
        this.dialog = false;
        this.$swal.fire({
          title: res.data.message,
          position: "top-start",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1500,
          icon: "success",
          customClass: {
            icon: "my-custom-class",
            title: "swal2-title", // Define your custom class here
          },
        });
      });
    },
    providers(id) {
      this.$router.push({ name: "provider", params: { id } });
    },
    async service() {
      let response = await crudDataService.getAll(`services`);
      this.serv = response.data.data;
    },
    gotomap(lang, lat) {
      this.$router.push({
        name: "MapAd",
        params: { param1: `${lang}`, param2: `${lat}` },
      });
    },
    async Singlead() {
      let response = await crudDataService.get(
        `show-ad`,
        `${this.$route.params.id}`
      );
      this.addetail = response.data.data.ad;
      response.data.data.ad.attachment.forEach(element => {
        this.itemsimage.push({
        src: element,
          thumbnail: element,
          w: 300,
          h: 300
         
      })

      });
     
      this.user = response.data.data.ad.user;
      // // console.log(this.user);
    },
  },
  mounted() {
    this.service();
    this.Singlead();
  },
};
</script>
<style>
 .iconssocials{
        background: rgb(246, 199, 18);
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: grid;
    text-align: center;
    place-items: center;
    text-decoration: none;
    margin-bottom: 5px;
    }
.my-custom-class {
  width: 55px; /* or any size */
  height: 55px; /* or any size */
  transform: scale(0.5);
  margin-top: 0 !important; /* Adjust the scaling factor as needed */
}
.swal2-title {
  display: inline-block; /* Display title inline with the icon */
  vertical-align: middle; /* Align the text vertically in the middle */
  line-height: normal;
  padding-top: 0 !important;
  padding-right: 15px !important;
}
.swal2-popup {
  display: flex !important;
  padding-bottom: 0 !important;
  width: 25em;
  align-items: center;
  height: 55px;
  font-size: 12px;
}
.my-gallery{
  display: flex;
  justify-content: center;
}
.gallery-thumbnail img{
  width: 300px;
  height: 300px;
  object-fit: cover;
}
.pswp__zoom-wrap{
  position: relative;
  /* Other styling for your content container */
  height: 100%;
}

.pswp__zoom-wrap::after {
  content: '';
  position: absolute;
  top: -60px;
  left: -60%;
  right: 0;
  bottom: 0;
  background-image: url('../../assets/logo.png'); /* Path to your watermark image */
  background-repeat: no-repeat;
  background-position: center; /* Adjust as needed */
  opacity: 0.5; /* Adjust watermark opacity */
  pointer-events: none; /* Allows clicks to pass through */
}
 .pswp__zoom-wrap{
 transform :translate3d(380px, 20px, 0px) scale(1) !important;

 }
</style>
<style lang="scss" scoped>

.watermarked {
  position: relative;
  /* Other styling for your content container */
  width:max-content ;
}

.watermarked::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../assets/logo.png'); /* Path to your watermark image */
  background-repeat: no-repeat;
  background-position: center; /* Adjust as needed */
  opacity: 0.5; /* Adjust watermark opacity */
  pointer-events: none; /* Allows clicks to pass through */
}
</style>