<template>
  <div v-if="show_">

    <v-container>
      <h5 class="text-red">* {{$t('title_val_license')}}</h5>
      <div class="text-red">{{ errorMessage ? errorMessage : "" }}</div>
      <div class="d-flex w-100">

        <div class="col-md-6">
          <label class="font-weight-bold">
            {{ $t("image_val_license") }}
          </label>
          <div class="rounded border my-2" style=" height: 50px !important;">

            <v-file-input
                variant="solo"
                class="selectnew my-0 ps-3"
                label="Add Image"
                prepend-icon="mdi-camera"
                accept=".pdf, image/jpeg, image/png"
                @change="handleFileChange"
            ></v-file-input>
          </div>

        </div>
        <div class="col-md-6">


          <div class="text-center">
            <button class="previewads w-50 pa-2 mt-5" @click="verifiedRequest">
              {{ $t("Verify") }}
            </button>
          </div>
        </div>
      </div>
    </v-container>
    <v-container v-if="useritem.val_license">

      <div class="d-flex">
        <img :src="useritem.val_license"
             width="200"
             height="200"
             class="image-nafath">

      </div>
    </v-container>
  </div>
</template>
<script>
import crudDataService from "@/Services/crudDataService";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      show_: false,
      useritem: '',
      errorMessage: '',
      random: '20',
      transId: '',
      show_random: false,
      optionsIdentityType: [],
      formData: {
        val_license:"",
      }
    };
    
  },
  methods: {


    async userprof(){
      let user=await crudDataService.get('user-profile',localStorage.getItem("userId"))
      this.useritem=user.data.data
      this.show_=true
    },
    handleFileChange(event) {
      this.formData.val_license = event.target.files.item(0);
      console.log(this.formData.val_license);
    },
    async verifiedRequest() {

      await crudDataService
          .create(`val-license`, this.formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
          .then((response) => {
            if(response.data.message == "failed"){
              if(response.data.error == 'Invalid Request,There Is Active Trx'){
                this.errorMessage = this.$t("Invalid Request");
              }else {
                this.errorMessage = response.data.error;
              }

            }else {
              window.location.reload();
            }

          })
          .catch((error) => {
            if (error.response) {
              this.errorMessage = error.response.data.message;
            } else if (error.request) {
              this.errorMessage = "No response from the server";
            } else {
              this.errorMessage = error.message;
            }
          });
    },

  },

  mounted() {
    this.userprof();

  },
  
};
</script>
<style lang="scss" scoped>
.button-prof {
  width: calc(97% / 2) !important;
}


.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-top: var(--bs-gutter-y);
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
}
span.number_class {
  margin: auto;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
  padding: 10px 15px;
  font-size: 18px;
  border-radius: 50%;
  background-color: #e7e7e7a1;
}
.is_have_con{
  margin: 10px;
  padding: 10px;
  color: #fd0000;
  width: 40% !important;
  border-radius: 10px;
  background-color: #e7575761;
}
.is_not_have_con{
  margin: 10px;
  padding: 10px;
  color: #ffffff;
  width: 40% !important;
  border-radius: 10px;
  background-color: #00d37b;
}
.step_previous{
  position: absolute;
  float: left;
  left: 0;
  color: #c30000;
  font-weight: 600;
  font-size: 18px;
}
img.image-nafath {
  margin: auto;
  border-radius: 15px;
}
@media (min-width: 768px){
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
</style>
