<template>
  <v-row>
    <v-col cols="12" md="2">
      <Multiselect
        :options="optionsarea"
        :searchable="true"
        :placeholder="$t('Area')"
        label="name"
        class="selectinput"
      />
    </v-col>
    <v-col cols="12" md="2">
      <multiselect
      :options="optionscity"
        :searchable="true"
        :placeholder="$t('City')"
        label="name"
        class="selectinput"
      ></multiselect>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        class="position-relative custominput "
        density="compact"
        :placeholder="$t('Placesearch')"
        single-line
        hide-details
        elevation="0"
      >
        <div
          class="position-absolute d-flex justify-space-between align-center"
          style="width: 96% !important"
        >
          <v-icon icon="mdi-magnify"> </v-icon>
          <v-icon icon="mdi-filter-outline"></v-icon>
        </div>
      </v-text-field>
    </v-col>
    <v-col cols="12" md="2">
      <v-btn class="previewads" elevation="0">
        {{ $t("Searchnow") }}
        <v-icon class="arrow" icon="mdi-chevron-left"> </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import crudDataService from "@/Services/crudDataService";

export default {
  data() {
    return {
      optionscity: [],
      optionsarea: [],
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    async Areas() {
      let response = await crudDataService.getAll(`areas`);
      this.optionsarea = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));

    },
    async City() {
      let response = await crudDataService.getAll(`cities`);
      this.optionscity = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));

    },
  },
  mounted() {
    this.Areas();
    this.City()
  },
};
</script>
<style lang="scss">
.custominput {
  &.v-text-field .v-field__input {
    align-items: center;
    input {
      margin-right: 30px;
      margin-left: 30px;
    }
  }
  .v-field--variant-filled .v-field__outline::before,
  .v-field--variant-underlined .v-field__outline::before {
    border-style: none;
  }
}
</style>

<style lang="scss">
.selectinput {
&.multiselect{
  border: none !important;
  height: 100%;
}
 .multiselect-search {
  background: #d9d9d938 !important;
}}
</style>
