<template>
  <v-container v-if="user.length>=1">
    <div class="breadcrumbs my-5">
      <div
        class="font-weight-bold"
        style="font-size: 30px; cursor: pointer"
        @click="$router.go(-1)"
      >
        {{ $t("Profile") }}
      </div>
      <v-icon icon="mdi-slash-forward" style="font-size: 40px"></v-icon>
      <p style="font-size: 24px">
        {{ $t("Site_balance") }}
      </p>
    </div>
    <v-card
      class="pa-xs-5 px-2 card_update"
      elevation="0"
      v-for="(item, i) in user"
      :key="i"
    >
      <div class="item_ads">
        <h4>{{ $t("current_package") }} :</h4>
        <p>
          {{ item.subscription[0].name }}
        </p>
      </div>
      <div class="item_ads">
        <h4>{{ $t("free_ads_remaining") }} :</h4>
        <p>
          {{ item.free_ads }}
        </p>
      </div>
      <div class="item_ads">
        <h4>{{ $t("regular_ads_remaining") }} :</h4>
        <p>
          {{ item.subscription[0].regular_ads }}
        </p>
      </div>
      <div class="item_ads">
        <h4>{{ $t("special_ads_remaining") }} :</h4>
        <p>
          {{ item.subscription[0].special_ads }}
        </p>
      </div>
      <div class="item_ads">
        <h4>{{ $t("expiration_date") }} :</h4>
        <p>
          {{ item.subscription[0].end_date }}
        </p>
      </div>

      <div class="mt-md-16 mt-3">
        <button
          class="rounded d-block py-3 mb-3 ma-auto font-weight-medium"
          style="width: 25%; border: 2px solid #f6c712; font-size: 24px"
          @click="Subscriptions()"
        >
          {{ $t("Subscriptions") }}
        </button>
      </div>
    </v-card>
  </v-container>
  <v-container v-else>
      <v-row>
        <v-col cols="12" v-for="text in texts" :key="text">
          <v-skeleton-loader
            class="mx-auto border-0"
            type="paragraph"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import crudDataService from "@/Services/crudDataService";

export default {
  data() {
    return {
      user: [],
      texts: [1, 2, 3, 4,5],

    };
  },
  methods: {
    async userprofile() {
      const id = localStorage.getItem("userId");
      let response = await crudDataService.get(`user-profile`, id);
      this.user.push(response.data.data);
    },
    Subscriptions() {
      this.$router.push({ name: "Subscriptions" });
    },
  },
  mounted() {
    this.userprofile();
  },
};
</script>
<style lang="scss" scoped>
.item_ads {
  display: flex;
  align-items: center;
  > h4 {
    font-size: 22px;
    color: #000;
    font-weight: 700;
  }
  > p {
    font-size: 20px;
  }
}
@media (max-width: 400px) {
  .item_ads {
    > h4 {
      font-size: 17px;
    }
    > p {
      font-size: 14px;
    }
  }
}
</style>
