<template>
  <v-container>
    <Real_Service />
  </v-container>
</template>

<script>
import Real_Service from "../components/Real_Service/Real_Service.vue";

export default {
  components: {
    Real_Service,
  },
};
</script>
