<template>
    <v-container>
        <Idea_Policy/>
    </v-container>
    </template>
    
    <script>
    import Idea_Policy from '@/components/Idea_Policy/Idea_Policy.vue'
    export default {
    
      components: {
        Idea_Policy
      },
    };
    </script>
    