<template >
    <section>
    <div
          style="
          position: fixed;
    right: 10%;
    bottom:10px;
    z-index: 555;
    cursor: pointer;
          "
          v-if="user.whatsapp"
        >
     
          <a :href="`tel:${user.whatsapp}`" class="text-black iconwhatsapp"
        >
            <v-icon icon="mdi-whatsapp" class="mx-1" size="35"></v-icon
          ></a>
  
        </div>
    </section>
    
</template>
<script>
import crudDataService from '../../Services/crudDataService';
export default {
    data(){
        return{
            user:''
        }
    },
    methods:{
        async userprofile() {
      const id = localStorage.getItem("userId");
      if (id) {
        let response = await crudDataService.get(`user-profile`, id);
        this.user=response.data.data;
      
      }
     
     
    },
    },
    mounted() {
        this.userprofile()
    },
}
</script>
<style>
     .iconwhatsapp{
        background: rgb(246, 199, 18);
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: grid;
    text-align: center;
    place-items: center;
    text-decoration: none;
    margin-bottom: 5px;
    }
</style>