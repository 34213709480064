<template>
  <section v-if="items.length>0">
    <div class="text-center my-6">
      <p class="text-h4 font-weight-bold">
        {{ $t("Blog") }}
      </p>
      <p style="color: #00000087; font-size: 20px" class="font-weight-bold">
        {{ $t("Through_section") }}
      </p>
    </div>
    <v-row>
      <v-col v-for="(item, i) in items" :key="i" :cols="12" class="py-0">
        <v-card height="100%"  class="custom-card" @click="singleblog(item.id)">
          <v-row align="center" >
            <v-col cols="12" md="3">
              <v-img
            :src="item.image"
            height="200px"
            class="rounded imgblog ma-3"
        
          ></v-img>
            </v-col>
            <v-col cols="12" md="9">
        
              <h3 class="font-weight-bold">
              {{ item.title }}
            </h3>
            <div class="wrapper">
              <p class="">
                {{ item.description }}
              </p>
            </div>
          
        
            </v-col>
     
          </v-row>         
        </v-card>
      </v-col>
    </v-row>
  </section>
  <v-row v-else >
  
  <v-col cols="12" >
    <v-skeleton-loader
      class="mx-auto border-0 "
      type="image,paragraph"
    ></v-skeleton-loader>
  </v-col>
  <v-col cols="12" >
    <v-skeleton-loader
      class="mx-auto border-0 "
      type="image,paragraph"
    ></v-skeleton-loader>
  </v-col>

</v-row>
</template>

<script>
import crudDataService from "@/Services/crudDataService";
export default {
  data() {
    return {
      items: [],
      itemsPerRow: 3,
    };
  },

  methods: {
    async blog() {
      let response = await crudDataService.getAll(`blogs`);
      this.items = response.data.data;
      // console.log(this.items);
    },
    singleblog(id){
this.$router.push({ name: 'SingleBlog', params:{ id } })

    },
    // calculateCols(index) {
    //   const totalItems = this.items.length;

    //   const itemsInCurrentRow =
    //     index % this.itemsPerRow === 0
    //       ? this.itemsPerRow
    //       : index % this.itemsPerRow;
    //   return (
    //     Math.floor(
    //       12 / (totalItems >= this.itemsPerRow ? this.itemsPerRow : totalItems)
    //     ) * itemsInCurrentRow
    //   );
    // },
  },
  mounted() {
    this.blog();
  },
};
</script>
<style>
.imgblog .v-img__img--contain {
  object-fit: scale-down;
  border: 2px solid var(--primary-color);
  border-radius: 20px;
}
</style>
<style scoped>
.wrapper {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  font-size: 18px;
  height: 100%;
}
.custom-card {
  cursor: pointer;
  box-shadow: none;
  border-bottom: 1px solid #adacac7e;
  border-radius: 0;
}
</style>
