<template>
  <section>
    <button class="individual" @click="open()">
      <p>
        {{ $t("Individual_packages") }}
      </p>
      <v-icon icon="mdi-chevron-up" v-if="style"></v-icon>

      <v-icon icon="mdi-chevron-down" v-else></v-icon>
    </button>
    <div :class="[style ? 'showing' : 'hiding']">
      <v-card
        class="mt-5 pa-5 bg-grey-lighten-4 card_subscribe"
        v-for="(ind, i) in individual"
        :key="i"
      >
        <v-card-title
          class="d-flex flex-sm-row flex-column justify-space-between font-weight-bold"
        >
          <span>{{ ind.name }}</span>
          <span
            >{{ ind.price }}

            {{ $t("rs") }}
          </span>
        </v-card-title>
        <v-list class="ps-sm-16 ps-2 ms-sm-15 ms-2 bg-grey-lighten-4">
          <v-list-item v-for="(pack, i) in ind.features" :key="i">
            <v-list-item-title class="font-weight-medium">
              <v-icon icon="mdi-check-circle" class="text-green"></v-icon>
              {{ pack }}
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <h4 class="py-2">المعلومات الاضافية للباقة</h4>
        <p class="pb-4">باقة تتيح لك عدة مزايا</p>
        <div class="d-flex flex-sm-row flex-column">
          <v-text-field
            density="compact"
            variant="solo"
            single-line
            hide-details
            class="rounded font-weight-medium w-75 me-sm-3 me-0 input_discount"
            elevation="0"
            :placeholder="$t('Discount')"
            style="box-shadow: none"
            v-model="coupon"
          >
          </v-text-field>

          <button
            class="w-25 text-white mx-auto mt-sm-0 mt-2 pa-sm-0 pa-2 rounded send_code"
            style="background-color: #f6c712"
            @click="send(ind)"
          >
            {{ $t("Send") }}
          </button>
        </div>
        <div class="text-red pt-2">{{ errorMessage ? errorMessage : "" }}</div>
        <div class="text-center">
          <button
            class="w-100 pa-sm-3 pa-1 rounded mt-sm-8 mt-3"
            style="border: 3px solid #d9d9d9; font-size: 23px"
            @click="dialog = true"
          >
            {{ $t("Payment_method") }}
          </button>
          <button
            class="w-75 mt-4 pa-2 rounded font-weight-bold"
            style="background-color: #f6c712"
            @click="subscriptionStore(ind.id)"
          >
            {{ $t("Subscribe_now") }}
          </button>
        </div>

        <v-dialog width="800" v-model="dialog">
          <v-card class="form_select pa-3">
            <v-card-title class="text-center text-h5 mb-5 font-weight-bold">
              {{ $t("Payment_method") }}
            </v-card-title>
            <v-form @submit.prevent="saveselect" enctype="multipart/form-data">
              <v-checkbox
                v-model="selected"
                :label="$t('Electronic_payment')"
                value="0"
                @change="changefile()"
              ></v-checkbox>
              <v-checkbox
                v-model="selected"
                :label="$t('Bank')"
                value="1"
                @change="changefile()"
              ></v-checkbox>
              <div v-if="imagefile" 
              class=" justify-center rounded elevation-2 mb-3"
              style="display: grid;"
              >
                <input
                  ref="file"
                  accept=".pdf, image/jpeg, image/png"
                  type="file"
                  class="pa-5 w-100"
                  @change="handleFileChange"
                />
                <!-- <v-file-input
          v-model="selectedFile"
          ref="fileInput"
          label="Choose an image"
          accept="image/*"
          @change="handleFileChange"
        ></v-file-input> -->
              </div>

              <v-btn
                class="w-100 elevation-0 bg-yellow-darken-2 font-weight-bold"
                type="submit"
              >
                {{ $t("Save") }}
              </v-btn>
            </v-form>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </section>
</template>
<script>
import crudDataService from "@/Services/crudDataService";
import axios from "axios";
export default {
  data() {
    return {
      style: false,
      individual: [],
      coupon: "",
      errorMessage: "",
      selected: null,
      dialog: false,
      selectedFile: null,
      imagefile: false,      
    };
  },

  methods: {
    handleFileChange(event) {
      this.selectedFile = event.target.files.item(0);
    },
    changefile() {
      if (this.selected == 1) {
        this.imagefile = !this.imagefile;
      }
    },

    saveselect() {
      this.dialog = false;
    },
    open() {
      this.style = !this.style;
    },
    async send(obj) {
      await crudDataService
        .create(`coupons/check`, {
          coupon: this.coupon,
          price: obj.price,
          subscription_id: obj.id,
        })
        .then((res) => {
          // console.log(res.data.data, "wdjqpowjpwd");
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message;
        });
    },
    async package() {
      let response = await crudDataService.getAll(`subscriptions`);
      response.data.data.forEach((element) => {
        if (element.premium == 0) {
          this.individual.push(element);
        }
      });
    },

    async subscriptionStore(id) {
      let form = new FormData();
      if (this.selected == 1) {
        form.append("receipt", this.selectedFile);
      }
      form.append("subscription_id", id);
        form.append("payment_id", this.selected);
        form.append("coupon", this.coupon);
        form.append("payment_method", "online");
      let res = await crudDataService.create(`subscription/store`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      window.location.href = res.data.data.url;
    },
  },
  mounted() {
    this.package();
  },
};
</script>
<style lang="scss">
.form_select {
  .v-selection-control__wrapper {
    display: none;
  }
  .v-checkbox .v-selection-control {
    padding-right: 5px;
    padding-left: 5px;
    border: 2px solid #f6c712;
    border-radius: 4px;
    justify-content: center;
  }
  .v-label--clickable {
    cursor: pointer;
    font-size: 18px;
    color: #000;
    font-weight: 700;
    width: 100%;
    justify-content: center;
  }
  .v-label {
    opacity: 1;
  }
}
.card_subscribe .v-input--density-compact .v-field--variant-outlined,
.card_subscribe .v-input--density-compact .v-field--single-line,
.card_subscribe .v-input--density-compact .v-field--no-label {
  box-shadow: none;
  background: transparent;
  border: 3px solid #d9d9d9 !important;
}
@media (max-width: 599.9px) {
  .card_subscribe .send_code,
  .card_subscribe .input_discount {
    width: 100% !important;
  }
}
</style>
<style lang="scss" scoped>
.card_subscribe {
  .v-text-field input.v-field__input {
    padding: 20px;
  }
}
.send_code {
  font-size: 22px;
}
.individual {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #f6c712;
  width: 100%;
  border-radius: 5px;
  padding: 15px;
  font-weight: 500;
  font-size: 22px;
}
.hiding {
  display: none;
}
.showing {
  display: block;
}
</style>
