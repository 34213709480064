<template>
    <v-container>
      <SinglePartner />
    </v-container>
  </template>
  
  <script>
  import SinglePartner from "../components/Singlepartner/SinglePartner.vue";
  
  export default {
    components: {
        SinglePartner,
    },
  };
  </script>
  