<template>
  <div>
    <Story/>
    <Carousel/>
    <Tabs/>
    <!-- <v-container> -->
    <Most/>
    <Partners/>
    <!-- <Real_estate/> -->
    <!-- </v-container> -->
   
  </div>

</template>

<script>
import { defineComponent } from 'vue';
import Story from '../components/Stories/Story.vue';
import Carousel from '../components/Carousel/Carousel.vue';
import Fillter from '../components/Fillter/Fillter.vue';
import Tabs from '../components/Tabs/Tabs.vue';
import Most from '../components/Most/Most.vue';
import Partners from '../components/Partners/Partners.vue';
import Real_estate from '../components/Real_estate/Real_estate.vue';
import {useAuth} from "@/axios";

export default defineComponent({
  name: 'HomeView',


  components: {
    Story,
    Carousel,
    Fillter,
    Tabs,
    Most,
    Partners,
    Real_estate
  },
  mounted() {
    // localStorage.removeItem("userId");
    // localStorage.removeItem("authToken");
    // const {clearToken} = useAuth();
    // clearToken()
    // window.location.reload();
  },
});
</script>
