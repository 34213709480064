<template>
  <section class="footer mt-md-10 pt-4">
    <v-container>
      <v-row>
        <v-col cols="12" lg="3" class="pl-lg-8 pl-0">
          <div
            class="d-flex align-center"
            @click="navigateTo('/')"
            style="cursor: pointer"
          >
            <v-img
              src="../../assets/logo.png"
              width="65"
              height="65"
              class="flex-0-0"
            ></v-img>
            <p class="px-2 header">{{ $t("Brand") }}</p>
          </div>
          <p style=" font-size: 19px" class="font-weight-regular">
            {{ items.description }}
          </p>
        </v-col>
            
          <v-col cols="12" lg="2" md="6" class="pr-lg-8 pr-0">
          <h3 class="mt-md-4 mt-0 mb-md-3">
            {{ $t("around") }}
          </h3>
          <div
            v-for="link in links"
            :key="link.text"
            class="font-weight-regular text-sm-cnter text-start"
            style=" font-size: 19px"
          >
            <p class="pb-3" @click="navigateTo(link.route)">
              {{ $t(link.text) }}
            </p>
          </div>
      
       
        </v-col>
        <v-col cols="12" lg="2" md="6">
          <h3 class="mt-md-4 mt-0 mb-md-3">
            {{ $t("Our_services") }}
          </h3>
          <div
            v-for="link in itembutton"
            :key="link.id"
            class="font-weight-regular text-sm-cnter text-start"
            style=" font-size: 19px"
          >
            <p
              class="pb-3"
              @click="scrollToSection('target-section','','','',link.text)"
            >
              {{ $t(link.title) }}
            </p>

          
          </div>
          <div
          
            class="font-weight-regular text-sm-cnter text-start"
            style=" font-size: 19px"
          >
<!--          <p-->
<!--              class="pb-3"-->
<!--              @click="scrollToSection('target-section','','','supportive_services','')"-->
<!--            >-->
<!--              {{ $t('supportive_services') }}-->
<!--            </p>-->

          
          </div>
          <div
          
          class="font-weight-medium text-sm-cnter text-start"
          style=" font-size: 19px"
        >
        <p
            class="pb-3"
            @click="navigateTo('/real-estate')"
          >
            {{ $t('Real_estate') }}
          </p>

        
        </div>
         
        </v-col>
        <v-col cols="12" lg="3">
          <h3 class="mt-md-4 mt-0 mb-md-3">
            {{ $t("Search_options") }}
          </h3>
          <v-row>
            <v-col cols="6" v-for="search in list" :key="search">
              <p
                @click="
                  scrollToSection(
                    'target-section',
                    search.estate_type_id,
                    search.ad_type_id
                  )
                "
                class="font-weight-regular"
                style=" font-size: 19px"
              >
                {{ search.name }}
              </p>
            </v-col>
          </v-row>
        </v-col>
    <v-col cols="8" lg="2" md="3"> 
          <div class="d-flex w-100 justify-space-around mt-lg-6">
            <a
                :href="`${items.twitter}`"
                target="_blank"
                class="text-decoration-none text-white"
              >
              <v-icon>
            mdi-twitter
          </v-icon>
              </a>
              <a
                :href="`${items.instagram}`"
                target="_blank"
                class="text-decoration-none text-white"
              >
              <v-icon>
            mdi-instagram
          </v-icon>
              </a>
              <a
                :href="`${items.facebook}`"
                target="_blank"
                class="text-decoration-none text-white"
              >
              <v-icon>
            mdi-facebook
          </v-icon>
              </a>
        <a
                :href="`${items.snapchat}`"
                target="_blank"
                class="text-decoration-none text-white"
              >
              <v-icon>
            mdi-snapchat
          </v-icon>
              </a>
          </div>
       <div class="mt-5">
        <a
                :href="`${items.google_play}`"
                target="_blank"
                class="text-decoration-none text-white"
              >
              <img :src="google" class="w-100 rounded mb-1" alt="">

              </a>
              <a
                :href="`${items.app_store}`"
                target="_blank"
                class="text-decoration-none text-white"
              >
              <img :src="app" class="w-100 rounded mb-1" alt="">
              </a>
              <a
                :href="`${items.huawei_store}`"
                target="_blank"
                class="text-decoration-none text-white"
              >
              <img :src="exp" class="w-100 rounded mb-1" alt="">
              </a>
       </div>
        </v-col>
      </v-row>
    </v-container>
      <v-divider></v-divider>
    <v-container>
      <v-row class="text-center">
        <v-col cols="12" class="">
          <p>
            {{ $t(footerText) }}
         <span style="color: #F6C712;">
          @
          {{ $t("Brand") }}
         </span>
          </p>
        
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import crudDataService from "@/Services/crudDataService";
import google from "@/assets/images/pngwing.jpeg";
import app from "@/assets/images/pngw.png";
import exp from "@/assets/images/pngwi.jpg";
import face from "@/assets/icon/face.png";
import twitter from "@/assets/icon/twitter.png";
export default {
  data() {
    return {
      links: [
        { text: "privacy", route: "/privacy" },
        { text: "About", route: "/about-us" },
        { text: "Contact", route: "/contact" },
        { text: "Terms", route: "/terms" },
        { text: "Adsconditions", route: "/ads-conditions" },
        { text: "Agreement", route: "/agreement" },
      ],
      twitter: twitter,
      face: face,
      google:google,
      app:app,
      exp:exp,
      footerText: "all_rights",
      items: "",
      list: "",
      itembutton: [
        { title: "Real_offers", id: 1, text: "sell" },
        { title: "Real_requests", id: 2, text: "buy" },
      ],
   
    };
  },
  methods: {
    async alltypes() {
      let response = await crudDataService.getAll(`types`);
      this.list = response.data.data.slice(0, 8);
    },
    scrollToSection(sectionId, estate_type, ad_type,support, param) {
      
      // console.log(param);
      this.$router.push({
        path: "/",
        query: {
          estate_type: estate_type ? estate_type : "",
          ad_type: ad_type ? ad_type : "",
          support: support ? support : "",
          param: param 
        },
      });
      this.$nextTick(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      });
    },

    navigateTo(route) {
      this.$router.push(route);
    },
    async getcontact() {
      let response = await crudDataService.getAll("application-data");
      this.items = response.data.data;
    },
  },
  mounted() {
    this.getcontact();
    this.alltypes();
  },
};
</script>
<style lang="scss" scoped>
.footer {
  background: #1f2937;
  color: white;
  cursor: pointer;
  .sochial {
    width: 24px;
  }
}
@media (min-width: 960px){
.footer .v-container {
    max-width: 1108px;
}}
@media (min-width: 1280px){
.footer .v-container {
    max-width: 1320px;
  // min-width: 1200px;

}

}
</style>
