<template>
  <v-container v-if="items.length > 0">
    <div class="breadcrumbs my-5">
      <div
        class="font-weight-bold"
        style="font-size: 30px; cursor: pointer"
        @click="$router.go(-1)"
      >
        {{ $t("Profile") }}
      </div>
      <v-icon icon="mdi-slash-forward" style="font-size: 40px"></v-icon>
      <p style="font-size: 24px">
        {{ $t("My_ads") }}
      </p>
    </div>
    <v-row class="justify-center">
      <v-col cols="12" md="7">
        <div class="collection-real" v-if="items.length > 0">
          <button
            class="button-real button-prof mx-1"
            :class="{ active: ff.active }"
            @click="filteredItems($t(ff.text))"
            v-for="ff in itembutton"
            :key="ff.id"
          >
            <p @click="active()">
              {{ $t(ff.title) }}
            </p>
          </button>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" v-for="(ad, i) in filler_item" :key="i">
        <Card
          :image="ad.attachment[0]"
          :title="ad.price"
          :subtitle="ad.estate_type + ad.ad_type"
          :city="ad.city"
          :date="ad.publish_date"
          :type="ad.estate_type"
          :useritem_name="useritem?useritem.name:''"
          :useritem_image="useritem?useritem.image:''"
          :useritem_id="useritem?useritem.id:0"
        ></Card>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
      <v-row>
        <v-col cols="12" v-for="text in texts" :key="text">
          <v-skeleton-loader
            class="mx-auto border-0"
            type="paragraph"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import Card from "../Card/MyCard.vue";
import crudDataService from "@/Services/crudDataService";
export default {
  components: {
    Card,
  },
  data() {
    return {
      useritem: '',
      items: [],
      filler_item: [],
      page: 1,
      texts: [1, 2, 3, 4,5],

      itembutton: [
        { title: "Real_offers", id: 1, text: "sell", active: true },
        { title: "Real_requests", id: 2, text: "buy", active: false },
      ],
      searchText: "sell",
    };
    
  },
  methods: {

    active() {
      this.itembutton.map((button, index) => {
        button.active = !button.active;
      });
    },
    async userprof(){
      let user=await crudDataService.get('user-profile',localStorage.getItem("userId"))
      this.useritem=user.data.data
    },

    async handlePageChange() {
      let response = await crudDataService.getAll(`user/ads`);
      this.items = response.data.data;

      this.filteredItems();
    },
    // filteredItems(text) {
    //   return this.filler_item=this.items.filter(item => item.main_type.toLowerCase().includes(text));
    // },
  async filteredItems(text = this.$t(this.searchText)) {
      return (this.filler_item = this.items.filter((item) =>
        item.main_type.toLowerCase().includes(text)
      ));
    },
  },
  mounted() {
    this.userprof();
    this.handlePageChange();

  },
  
};
</script>
<style lang="scss" scoped>
.button-prof {
  width: calc(97% / 2) !important;
}
</style>
>
