<template>
  <v-container>
    <Privacy/>
  </v-container>
</template>

<script>
import Privacy from '@/components/Privacy/PrivacyP.vue'
export default {

  components: {
    Privacy
  },
};
</script>
