<template>
  <v-container>
    <div class="breadcrumbs mt-5 mb-15">
      <div
        class="font-weight-bold"
        style="font-size: 30px; cursor: pointer"
        @click="$router.go(-1)"
      >
        {{ $t("Profile") }}
      </div>
      <v-icon icon="mdi-slash-forward" style="font-size: 40px"></v-icon>
      <p style="font-size: 24px">
        {{ $t("Subscriptions") }}
      </p>
    </div>
    <individual class="my-11"/>
    <institution class="my-11"/>
  </v-container>
</template>
<script>
import individual from './individual.vue';
import institution from './institution.vue';
export default {
  components:{
    individual,
    institution
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>
