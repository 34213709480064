<template>
  <section class="overflow-hidden" v-if="items.length > 0">
    <div class="text-center my-6">
      <p class="text-h4 font-weight-bold py-4">
        {{ $t("Service_providers") }}
      </p>
      <p
        style="color: #00000087; font-size: 20px"
        class="font-weight-bold py-4"
      >
        {{ title }}
      </p>
    </div>

    <div class="d-flex flex-wrap justify-start pt-7">
      <div
        class="itemreal"
        v-for="(item, i) in items"
        :key="i"
        @click="singlepartner(item.id)"
      >
      
        <v-img :src="item.image" class="w-100" style="height: 190px"></v-img>
        <p style="font-size: 20px" class="font-weight-medium text-center my-2">
          {{ item.name }}
        </p>
      </div>
    </div>
  </section>
  <v-row v-else class="skeleton">
    <v-col cols="12" md="12">
      <v-skeleton-loader type="paragraph" width="50%" class="ma-auto"></v-skeleton-loader>
    <v-skeleton-loader type="paragraph" width="50%" class="ma-auto"></v-skeleton-loader>
    <v-skeleton-loader type="paragraph" width="50%" class="ma-auto"></v-skeleton-loader>
    </v-col>
    <v-col cols="12" md="2" v-for="text in texts" :key="text">
      <v-skeleton-loader
        class="mx-auto border-0 rounded-circle"
        type="image"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>
<script>
import crudDataService from "@/Services/crudDataService";
export default {
  components: {},
  data() {
    return {
      items: [],
      title: "",
      texts:[1,2,3,4,5,6]

    };
  },
  methods: {
    async provider() {
      const response = await crudDataService.get(
        `services?service_id=${this.$route.params.id}`
      );
      this.items = response.data.data;
      response.data.data.forEach((element) => {
        this.title = element.name;
        this.items = element.users;
      });
    },
    singlepartner(id){
      this.$router.push({ name: 'SinglePartner', params:{ id } })
    }
  },
  mounted() {
    this.provider();
  },
};
</script>
<style lang="scss" >
.skeleton .v-skeleton-loader__image{
    border-radius: 50%
}
</style>
<style lang="scss" scoped>

.itemreal {
  width: calc(100% / 5);
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 30px;
  cursor: pointer;
}
@media (max-width: 991.98px) {
  .itemreal {
    width: calc(100% / 4);
  }
}
@media (max-width: 700px) {
  .itemreal {
    width: calc(100% / 3);
  }
}
@media (max-width: 600px) {
  .itemreal {
    width: calc(100% / 2);
    p {
      font-size: 12px !important;
    }
  }
}
</style>
