<template>
  <v-container>
    <LogIn />
  </v-container>
</template>

<script>
import LogIn from "../components/LogIn/LogIn.vue";
export default {
  components: {
    LogIn,
  },
};
</script>
