<template>
  <section v-if="items">
    <div class="text-center my-6">
      <p class="text-h4 font-weight-bold header-page">
        {{ $t("Adsconditions") }}
      </p>
    </div>

    <div v-html="items" class="text-content text-center"></div>
  </section>

  <v-row v-else>
    <v-col cols="12" v-for="text in texts" :key="text">
      <v-skeleton-loader
        class="mx-auto border-0"
        type="paragraph"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
import crudDataService from "@/Services/crudDataService";
export default {
  data() {
    return {
      items: "",
      texts: [1, 2, 3, 4,5],
    };
  },

  methods: {
    async adsconditions() {
      let response = await crudDataService.getAll("application-data");
      this.items = response.data.data.ad_conditions;
    },
  },
  mounted() {
    this.adsconditions();
  },
};
</script>
