<template>
  <section v-if="items" id="partnersingle">
    <!-- <div class="breadcrumbs mt-10">
      <div
        class="font-weight-bold "
        style="font-size: 40px; cursor: pointer"
        @click="$router.go(-1)"
      >
        {{ $t("Service_providers") }}
      </div>
      <v-icon icon="mdi-slash-forward" style="font-size: 40px"></v-icon>
      <p>{{ items.name }}</p>
    </div> -->
    <v-row justify="start" class="py-13 row-partner">
      <v-col cols="1" md="1">
      <div
          style="
   
    cursor: pointer;
          "
        >
          <a :href="`tel:+${items.phone}`" class="text-black iconssocials"
        >
            <v-icon icon="mdi-phone" class="mx-1"></v-icon
          ></a>
  
          <v-icon icon="mdi-forum-outline" class="iconssocials" @click="navigateTo('/chats')"></v-icon>
          <a
            :href="`https://api.whatsapp.com/send?phone=${items.whatsapp}`"
            target="_blank"
            class="text-black iconssocials"
          >
            <v-icon icon="mdi-whatsapp" class="mx-1"></v-icon>
          </a>
          <v-icon
            icon="mdi-block-helper"
            @click="dialog = true"
            class=" iconssocials"
          ></v-icon>
        </div>
      </v-col>
      <v-col cols="5" md="2">
        <img
          :src="items.image"
          alt=""
          class="w-100 rounded-circle image-partner"
        />
      </v-col>
      <v-col cols="6" md="9">
        <h2 class="mb-7 font-weight-bold">
          {{ items.name }}
        </h2>
       
        <button
          class="px-2 previewads mb-7 font-weight-medium"
          @click="follow"
          v-if="textchange"
        >
          {{ $t("follow") }}
        </button>
        <button
          class="px-2 previewads mb-5 font-weight-medium"
          @click="unfollow"
          v-else-if="items.is_followed==1"
        >
        {{ $t("unfollow") }}
        </button>

        <p
          v-for="item in items.subscription"
          :key="item.subscription_id"
          class="text-about"
        >
          {{ item.about }}
        </p>
      </v-col>
    </v-row>
    <User_Ads />
    <v-dialog v-model="dialog" width="500">
        <v-card class="text-center py-5">
          <img :src="warning" width="100" class="mx-auto" alt="" />
          <h3>
            {{ $t("Do_block_advertiser") }}
          </h3>
          <div class="d-flex justify-center align-center mt-2">
            <button
              @click="blocked(items.id)"
              class="previewads pa-1 mx-1 rounded"
              style="width: 100px"
            >
              {{ $t("yes") }}
            </button>
            <button
              @click="dialog = false"
              class="border rounded text-black pa-1 mx-1"
              style="width: 100px"
            >
              {{ $t("no") }}
            </button>
          </div>
        </v-card>
      </v-dialog>
  </section>
  <v-row v-else>
    <v-col cols="12" v-for="text in texts" :key="text">
      <v-skeleton-loader
        class="mx-auto border-0"
        type="paragraph"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>
<script>
import User_Ads from "./User_Ads";
import crudDataService from "@/Services/crudDataService";
export default {
  components: {
    User_Ads,
  },
  data() {
    return {
      dialog: false,
      items: "",
      textchange: "",
      texts: [1, 2, 3, 4,5],
    };
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
    async blocked(id) {
      await crudDataService.get(`user-block`, id).then((res) => {
        this.dialog = false;
        this.$swal.fire({
          title: res.data.message,
          position: "top-start",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1500,
          icon: "success",
          customClass: {
            icon: "my-custom-class",
            title: "swal2-title", // Define your custom class here
          },
        });
      });
    },
    async SinglePartner() {
      let response = await crudDataService.get(
        `user-profile`,
        `${this.$route.params.id}`
      );
      this.items = response.data.data;
      // // console.log(this.items);
    },
    async follow() {
      let res = await crudDataService.get(
        `user-follow`,
        `${this.$route.params.id}`
      );

      if (res.data.success) {
        this.textchange = !this.textchange;
      }
      // console.log(res.data);

    },
    async unfollow() {
      let res = await crudDataService.get(
        `user-unfollow`,
        `${this.$route.params.id}`
      );
      if (res.data.success) {
        this.textchange = !this.textchange;
      }
      // console.log(res.data);
    },
  },
  mounted() {
    this.SinglePartner();
  },
};
</script>
<style lang="scss">
.breadcrumbs {
  display: flex;
  align-items: center;
  p {
    color: #f6c712;
    font-size: 32px;
  }
}
@media (max-width: 550px) {
  .breadcrumbs {
    > div {
      font-size: 22px !important;
    }
    p {
      font-size: 20px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.text-about {
  font-size: 18px;
  color: #000;
  line-height: 1.8;
  font-weight: 500;
}
.previewads {
  width: 130px;
  height: 45px;
}
@media (max-width: 991.98px) {
  .row-partner {
    justify-content: center !important;
    text-align: center;
    .image-partner {
      width: 30% !important;
    }
  }
}
@media (max-width: 550px) {
  .breadcrumbs {
    > div {
      font-size: 25px !important;
    }
    p {
      font-size: 20px !important;
    }
  }
}
@media (max-width: 350px) {
  .breadcrumbs {
    display: none;
  }
}
@media (min-width: 960px){
#partnersingle .v-container {
    max-width: 1108px;
}}
@media (min-width: 1280px){
#partnersingle .v-container {
    max-width: 1470px;
  // min-width: 1200px;

}

}
</style>
